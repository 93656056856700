import { schedulerConstants } from "../constants/scheduler.constants";

export function scheduler(state = {}, action) {
  switch (action.type) {
    case schedulerConstants.GET_REQUEST:
    case schedulerConstants.CREATE_REQUEST:
    case schedulerConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        request: action.payload,
        error: false,
        success: false,
      };
    case schedulerConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        paginatedResult: action.payload,
      };
    case schedulerConstants.CREATE_SUCCESS:
    case schedulerConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case schedulerConstants.DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case schedulerConstants.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case schedulerConstants.CREATE_FAILURE:
    case schedulerConstants.GET_FAILURE:
    case schedulerConstants.DELETE_FAILURE:
    case schedulerConstants.UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
