import { invitationService } from "../services/invitation.service";
import { invitationConstants } from "../constants/invitation.constants";

export const invitationActions = {
  init,
  invite,
  close,
  getBySender,
};

function init() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return {
      type: invitationConstants.OPEN_MODAL,
    };
  }
}

function invite(
  fromFirstName,
  fromLastName,
  email,
  clientId,
  clientGuid,
  isAdmin
) {
  return (dispatch) => {
    dispatch(
      request({
        fromFirstName,
        fromLastName,
        email,
        clientId,
        clientGuid,
        isAdmin,
      })
    );

    invitationService
      .invite(fromFirstName, fromLastName, email, clientId, clientGuid, isAdmin)
      .then(
        (response) => {
          dispatch(success(response.data));
        },
        (error) => {
          dispatch(failure());
        }
      );
  };

  function request(requestParameters) {
    return {
      type: invitationConstants.SEND_REQUEST,
      payload: requestParameters,
    };
  }
  function success(paginatedResult) {
    return { type: invitationConstants.SEND_SUCCESS, payload: paginatedResult };
  }
  function failure() {
    return { type: invitationConstants.SEND_FAILURE };
  }
}

function close() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return {
      type: invitationConstants.CLOSE_MODAL,
    };
  }
}

function getBySender(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    invitationService.getBySender(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: invitationConstants.GET_BY_SENDER_ID_REQUEST,
      payload: requestParameters,
    };
  }
  function success(paginatedResult) {
    return {
      type: invitationConstants.GET_BY_SENDER_ID_SUCCESS,
      payload: paginatedResult,
    };
  }
  function failure() {
    return { type: invitationConstants.GET_BY_SENDER_ID_FAILURE };
  }
}
