export const runbookConstants = {
  GET_REQUEST: "GET_RUNBOOK_REQUEST",
  GET_SUCCESS: "GET_RUNBOOK_SUCCESS",
  GET_FAILURE: "GET_RUNBOOK_FAILURE",
  CREATE_REQUEST: "CREATE_RUNBOOK_REQUEST",
  CREATE_SUCCESS: "CREATE_RUNBOOK_SUCCESS",
  CREATE_FAILURE: "CREATE_RUNBOOK_FAILURE",
  UPDATE_REQUEST: "UPDATE_RUNBOOK_REQUEST",
  UPDATE_SUCCESS: "UPDATE_RUNBOOK_SUCCESS",
  UPDATE_FAILURE: "UPDATE_RUNBOOK_FAILURE",
  DELETE_REQUEST: "DELETE_RUNBOOK_REQUEST",
  DELETE_SUCCESS: "DELETE_RUNBOOK_SUCCESS",
  DELETE_FAILURE: "DELETE_RUNBOOK_FAILURE",
  EXECUTE_REQUEST: "EXECUTE_RUNBOOK_REQUEST",
  EXECUTE_SUCCESS: "EXECUTE_RUNBOOK_SUCCESS",
  EXECUTE_FAILURE: "EXECUTE_RUNBOOK_FAILURE",
  VALIDATE_REQUEST: "VALIDATE_RUNBOOK_REQUEST",
  VALIDATE_SUCCESS: "VALIDATE_RUNBOOK_SUCCESS",
  VALIDATE_FAILURE: "VALIDATE_RUNBOOK_FAILURE",
};
