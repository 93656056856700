import { useSelector } from "react-redux";
import ClientMenu from "./clientMenu";
import { Link } from "react-router-dom";
import { Nav, Dropdown } from "react-bootstrap";
import { AiOutlinePoweroff } from "react-icons/ai";
import { FaRegUserCircle } from "react-icons/fa";
import { FcInvite } from "react-icons/fc";
import { useDispatch } from "react-redux";
import { invitationActions } from "../../actions/invitation.actions";

function UserMenu({ user, onSignOut }) {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);

  const handleSendInviteClick = () => {
    dispatch(invitationActions.init());
  };

  return (
    <div className="user-menu">
      <Nav.Item className="d-flex">
        <ClientMenu user={user} />
        <Dropdown align={"end"}>
          <Dropdown.Toggle
            variant="link primary"
            className="text-decoration-none"
          >
            <FaRegUserCircle className="me-2 mb-1" />
            <small>
              <small className="fw-bold">
                {`${user.firstName} ${user.lastName}`}
              </small>
            </small>
          </Dropdown.Toggle>
          <Dropdown.Menu className="rounded-0 shadow-lg p-3">
            <Dropdown.Item as={"div"}>
              <Link to={"/profile"} className="text-decoration-none">
                <small>
                  <FaRegUserCircle className="me-2 mb-1" />
                  <span className="text-dark">Profile</span>
                </small>
              </Link>
            </Dropdown.Item>
            {user.isAdmin && (
              <Dropdown.Item
                onClick={handleSendInviteClick}
                className="text-decoration-none"
              >
                <small>
                  <FcInvite className="me-2 mb-1" />
                  <span className="text-dark">Send an invite</span>
                </small>
              </Dropdown.Item>
            )}
            <Dropdown.Item onClick={onSignOut}>
              <small>
                <AiOutlinePoweroff className="text-danger mb-1 me-2" />
                <span>Sign Out</span>
              </small>
            </Dropdown.Item>
            {user.isSuperUser && settings.result && (
              <Dropdown.Item disabled>
                <hr />
                <small>
                  <small className="text-muted">
                    Env: {settings.result.environment}
                  </small>
                </small>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Nav.Item>
    </div>
  );
}

export default UserMenu;
