import { broadcastMessageReviewConstants } from "../constants/broadcastMessageReview.constants";

const initialState = {
  loading: false,
  request: null,
  paginatedResult: null,
  success: false,
  error: false,
};

export function broadcastMessageReview(state = initialState, action) {
  switch (action.type) {
    case broadcastMessageReviewConstants.GET_BROADCAST_MESSAGE_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        request: action.payload,
        paginatedResult: null,
      };
    case broadcastMessageReviewConstants.GET_BROADCAST_MESSAGE_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        paginatedResult: action.payload,
      };
    case broadcastMessageReviewConstants.GET_BROADCAST_MESSAGE_REVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        paginatedResult: null,
      };
    case broadcastMessageReviewConstants.CREATE_BROADCAST_MESSAGE_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case broadcastMessageReviewConstants.CREATE_BROADCAST_MESSAGE_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case broadcastMessageReviewConstants.CREATE_BROADCAST_MESSAGE_REVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
