import React, { useState } from "react";
import { Container, Form, Button, Alert, Spinner } from "react-bootstrap";
import { userActions } from "../actions/user.actions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PasswordInput from "./common/PasswordInput";

function Login() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleLogin = async () => {
    setSubmitted(true);

    if (email && password) dispatch(userActions.login(email, password));
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") handleLogin();
  };

  return (
    <div className="centered-div">
      <Container
        className="login-box bg-white p-5 rounded border border-secondary mt-5"
        onKeyDown={onKeyDown}
      >
        <div className="text-center mb-5">
          <img
            src={require("../assets/armanino-llp.png")}
            alt="Armanino"
            width={250}
          />
        </div>

        {user.loginFailed && (
          <Alert variant={"danger"}>The email or password is incorrect</Alert>
        )}

        <div className="mb-3">
          Please provide your email address and password to access the Armanino
          Integration Portal.
        </div>

        <Form.Group controlId="formBasicEmail" className="mb-3">
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={submitted && !email ? "is-invalid" : ""}
          />
          <Form.Control.Feedback type="invalid">
            <small>Please provide your email.</small>
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formBasicPassword" className="mb-3">
          <PasswordInput
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            additionalCss={submitted && !password ? "is-invalid" : ""}
          />
          <Form.Control.Feedback type="invalid">
            <small>Please provide your password.</small>
          </Form.Control.Feedback>
        </Form.Group>

        <div className="d-grid gap-2 mb-3">
          <Button
            variant="primary"
            type="submit"
            className="mt-3"
            size="lg"
            onClick={handleLogin}
            disabled={user.loading}
          >
            {!user.loading ? <>Login</> : <Spinner size="sm" />}
          </Button>
        </div>
        <div className="text-center">
          <Link to={"/sign-in-help"} className="text-decoration-none">
            Forgot your password?
          </Link>
        </div>
      </Container>
    </div>
  );
}

export default Login;
