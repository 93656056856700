import { runbookExecutionHistoryLineConstants } from "../constants/runbookExecutionHistoryLine.constants";

export function runbookExecutionHistoryLine(state = {}, action) {
  switch (action.type) {
    case runbookExecutionHistoryLineConstants.GET_REQUEST:
      return {
        ...state,
        loading: true,
        request: action.payload,
      };
    case runbookExecutionHistoryLineConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        paginatedResult: action.payload,
      };
    case runbookExecutionHistoryLineConstants.GET_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
