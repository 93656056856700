import React, { useState, useEffect } from "react";
import { FcInvite } from "react-icons/fc";
import { Form, Button, Spinner, Alert, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Utils from "../../utils";
import { invitationActions } from "../../actions/invitation.actions";

function InviteModal({ user }) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const invitation = useSelector((state) => state.invitation);

  useEffect(() => {
    setEmail("");
    setIsAdmin(false);
    setSubmitted(false);

    // eslint-disable-next-line
  }, [invitation.visible]);

  const handleSend = async () => {
    setSubmitted(true);

    if (email && Utils.isEmailValid(email))
      dispatch(
        invitationActions.invite(
          user.firstName,
          user.lastName,
          email,
          user.clientId,
          user.clientGuid,
          isAdmin
        )
      );
  };

  const handleClose = () => {
    dispatch(invitationActions.close());
  };

  const getButtonText = (loading, success) => {
    if (loading) return <Spinner size="sm" />;
    if (success) return <>Invitation sent</>;
    return <>Send invitation</>;
  };

  return (
    <Modal show={invitation.visible} onHide={handleClose} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FcInvite className="mb-1 me-2" />
          Send an invite
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {submitted && !invitation.loading && invitation.request && (
          <>
            {!invitation.success && (
              <Alert variant={"danger"}>
                An error occurred, please contact Armanino for helping sending
                this invitation.
              </Alert>
            )}

            {invitation.success && (
              <Alert variant={"success"}>
                Your invitation to {email} has been sent succesfully.
              </Alert>
            )}
          </>
        )}

        {!submitted && user && user.isSuperUser && (
          <Alert variant={user.clientId ? "info" : "danger"}>
            <b>
              {user.clientId
                ? `${user.clientName} (ID: ${user.clientId}) will be used for the invitation. `
                : "BE CAREFUL, THE INVITED USER WILL HAVE SUPERUSER PERMISSIONS. "}
            </b>
            You can change that by selecting a different Client at the top right
            Clients dropdown.
          </Alert>
        )}

        <p>
          To invite someone to register for the Armanino Integration Portal,
          please enter their email address below. An invitation link will be
          sent to them to complete the registration process.
        </p>
        <Form.Group controlId="formBasicEmail" className="mb-3">
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={`${
              submitted && (!email || !Utils.isEmailValid(email))
                ? " is-invalid"
                : ""
            }`}
            maxLength={200}
          />
          <Form.Control.Feedback type="invalid">
            <small>Please provide a valid email.</small>
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="mb-3">
          <Form.Check
            type={"checkbox"}
            label={"Set Administrator permissions"}
            checked={isAdmin}
            onChange={(e) => setIsAdmin(e.target.checked)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSend}
          disabled={invitation.loading || invitation.success}
        >
          {getButtonText(invitation.loading, invitation.success)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default InviteModal;
