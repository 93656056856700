export const dashboardConstants = {
  GET_LATEST_EXECUTIONS_REQUEST: "GET_LATEST_EXECUTIONS_REQUEST",
  GET_LATEST_EXECUTIONS_SUCCESS: "GET_LATEST_EXECUTIONS_SUCCESS",
  GET_LATEST_EXECUTIONS_FAILURE: "GET_LATEST_EXECUTIONS_FAILURE",
  GET_NEXT_EXECUTIONS_REQUEST: "GET_NEXT_EXECUTIONS_REQUEST",
  GET_NEXT_EXECUTIONS_SUCCESS: "GET_NEXT_EXECUTIONS_SUCCESS",
  GET_NEXT_EXECUTIONS_FAILURE: "GET_NEXT_EXECUTIONS_FAILURE",
  GET_KPIS_REQUEST: "GET_KPIS_REQUEST",
  GET_KPIS_SUCCESS: "GET_KPIS_SUCCESS",
  GET_KPIS_FAILURE: "GET_KPIS_FAILURE",
};
