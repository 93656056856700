import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Title from "../common/Title";
import Grid from "../common/Grid";
import { FaPerson } from "react-icons/fa6";
import Utils from "../../utils";
import { userActions } from "../../actions/user.actions";
import UsersGridFilter from "./UsersGridFilter";
import { MdDelete, MdEdit } from "react-icons/md";
import ConfirmModal from "../common/ConfirmModal";
import { Badge } from "react-bootstrap";
import moment from "moment";
import GridHelper from "../helpers/gridHelper";

function UsersGrid(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userReducer = useSelector((state) => state.user);
  const [confirmDeleteModalShow, setConfirmDeleteModalShow] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState("");

  const filterInitialState = {
    firstName: "",
    lastName: "",
    email: "",
    isAdmin: null,
    isSuperUser: null,
  };
  const [filter, setFilter] = useState(filterInitialState);

  const actions = [
    {
      text: "Edit",
      url: "edit?userId={id}",
      icon: <MdEdit className="mb-1 me-2" />,
    },
    {
      text: "Delete",
      event: (id) => {
        setDeleteUserId(id);
        setConfirmDeleteModalShow(true);
      },
      icon: <MdDelete className="mb-1 me-2" />,
    },
  ];

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams["clientId"] = props.user.clientId;
    queryParams["clientGuid"] = props.user.clientGuid;

    dispatch(userActions.get(queryParams));

    // Initialize filter from URL
    setFilter({
      firstName: queryParams["firstName"],
      lastName: queryParams["lastName"],
      email: queryParams["email"],
      isAdmin: queryParams["isAdmin"],
      isSuperUser: queryParams["isSuperUser"],
    });

    // eslint-disable-next-line
  }, [location]);

  const handleClearFilters = () => {
    GridHelper.handleSearch(filterInitialState, location, navigate);
  };

  const handleDeleteUser = () => {
    setConfirmDeleteModalShow(false);
    let requestParameters = {
      userId: deleteUserId,
      clientId: props.user.clientId,
      clientGuid: props.user.clientGuid,
    };

    dispatch(userActions.remove(requestParameters));
  };

  let displayColumns = [
    "firstName",
    "lastName",
    "email",
    "isAdmin",
    "isSuperUser",
    "createdOn",
    "lastSignInDateOn",
  ];
  if (props.user.isSuperUser) displayColumns.push("clientName");

  const data = userReducer.paginatedResult?.data.map((u) => {
    return {
      ...u,
      createdOn: Utils.convertToDateOnly(u.createdOn),
      lastSignInDateOn: u.lastSignInDateOn
        ? moment(Utils.convertToDateTime(u.lastSignInDateOn)).fromNow()
        : "Never",
      isAdmin: u.isAdmin ? <Badge bg="primary">Admin</Badge> : "",
      isSuperUser: u.isSuperUser ? <Badge bg="primary">Super User</Badge> : "",
    };
  });

  return (
    <>
      <Title text="Users" icon={<FaPerson className="mb-1" />} />

      <UsersGridFilter filter={filter} onClearFilters={handleClearFilters} />

      <Grid
        data={data}
        displayColumns={displayColumns}
        idColumn={"userId"}
        actions={actions}
        pageNumber={userReducer.paginatedResult?.pageNumber ?? 0}
        totalPages={userReducer.paginatedResult?.totalPages ?? 0}
        loading={userReducer.loading}
        onPageChange={GridHelper.handlePageClick}
        onSort={GridHelper.handleSortClick}
      />

      <ConfirmModal
        show={confirmDeleteModalShow}
        title="Delete User"
        question={`Are you sure you want to delete this user?`}
        noButtonText="No"
        yesButtonText="Yes, please"
        handleClose={() => {
          setConfirmDeleteModalShow(false);
        }}
        handleConfirm={() => {
          handleDeleteUser();
        }}
      />
    </>
  );
}

export default UsersGrid;
