import React, { useEffect, useState } from "react";
import { MdAutorenew } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { clientActions } from "../../actions/client.actions";
import Utils from "../../utils";
import { clientConstants } from "../../constants/client.constants";
import Title from "../common/Title";
import { GoOrganization } from "react-icons/go";
import Grid from "../common/Grid";
import ClientGridFilter from "./ClientGridFilter";
import GridHelper from "../helpers/gridHelper";

function ClientGrid(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const client = useSelector((state) => state.client);

  const filterInitialState = {
    clientName: "",
  };
  const [filter, setFilter] = useState(filterInitialState);

  const actions = [
    {
      text: "Renew",
      url: "renew?clientId={id}",
      icon: <MdAutorenew className="mb-1 me-2" />,
    },
  ];

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);

    dispatch(
      clientActions.get(queryParams, clientConstants.CALLER_CLIENT_GRID)
    );

    // Initialize filter from URL
    setFilter({
      clientName: queryParams["clientName"],
    });

    // eslint-disable-next-line
  }, [location]);

  const handleClearFilters = () => {
    GridHelper.handleSearch(filterInitialState, location, navigate);
  };

  let displayColumns = ["clientName", "expiresOn"];

  const data = client.paginatedResultGrid?.data.map((c) => {
    return {
      ...c,
      expiresOn: Utils.convertToDateOnly(c.expiresOn),
    };
  });

  return (
    <>
      <Title text="Clients" icon={<GoOrganization className="mb-1" />} />

      <ClientGridFilter filter={filter} onClearFilters={handleClearFilters} />

      <Link to={"add"} className="btn btn-primary float-end mb-2">
        Add Client
      </Link>

      <Grid
        data={data}
        displayColumns={displayColumns}
        idColumn={"clientId"}
        actions={actions}
        pageNumber={client.paginatedResultGrid?.pageNumber ?? 0}
        totalPages={client.paginatedResultGrid?.totalPages ?? 0}
        loading={client.loadingGrid}
        onPageChange={GridHelper.handlePageClick}
        onSort={GridHelper.handleSortClick}
      ></Grid>
    </>
  );
}

export default ClientGrid;
