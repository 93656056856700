import GaugeChart from "react-gauge-chart";

function Gauge(props) {
  const colors = ["#dc3545", "#ffc107", "#198754"];

  const getTextColor = () => {
    if (props.percent <= 0.25) return colors[0];
    if (props.percent <= 0.5) return colors[1];
    return colors[2];
  };

  return (
    <GaugeChart
      animate={false}
      className={"pt-3"}
      arcsLength={[25, 25, 50]}
      percent={props.percent}
      hideText={false}
      textColor={getTextColor()}
      colors={colors}
      marginInPercent={0.025}
      arcPadding={0.03}
      needleColor={"#dee2e6"}
    />
  );
}

export default Gauge;
