import { Row, Col } from "react-bootstrap";

function TimeDisplay(props) {
  return (
    <>
      {props.time && props.time !== "00:00:00" ? (
        <Row>
          <Col xs={4}></Col>
          <Col className="text-center border shadow-sm py-2 mx-2">
            {props.time.split(":")[0]}
            <hr className="my-0" />
            <span className="fs-6">
              <small>Hours</small>
            </span>
          </Col>
          <Col className="text-center border shadow-sm py-2 mx-2">
            {props.time.split(":")[1]}
            <hr className="my-0" />
            <span className="fs-6">
              <small>Minutes</small>
            </span>
          </Col>
          <Col className="text-center border shadow-sm py-2 mx-2">
            {props.time.split(":")[2]}
            <hr className="my-0" />
            <span className="fs-6">
              <small>Seconds</small>
            </span>
          </Col>
          <Col xs={4}></Col>
        </Row>
      ) : (
        <>No executions in the last month </>
      )}
    </>
  );
}

export default TimeDisplay;
