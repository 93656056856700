import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Title from "../common/Title";
import Grid from "../common/Grid";
import Utils from "../../utils";
import { broadcastMessageReviewActions } from "../../actions/broadcastMessageReview.actions";
import { broadcastMessageActions } from "../../actions/broadcastMessage.actions";
import { FaEye } from "react-icons/fa";
import moment from "moment";
import BroadcastMessageReviewGridFilter from "./BroadcastMessageReviewGridFilter";
import { GiMegaphone } from "react-icons/gi";
import Subtitle from "../common/Subtitle";
import GridHelper from "../helpers/gridHelper";

function BroadcastMessageReviewGrid(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const broadcastMessageReview = useSelector(
    (state) => state.broadcastMessageReview
  );
  const broadcastMessage = useSelector((state) => state.broadcastMessage);

  const filterInitialState = {
    reviewer: "",
    clientName: "",
  };
  const [filter, setFilter] = useState(filterInitialState);

  const actions = [];

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams["clientId"] = props.user.clientId;
    queryParams["clientGuid"] = props.user.clientGuid;

    dispatch(broadcastMessageReviewActions.get(queryParams));
    dispatch(broadcastMessageActions.get(queryParams));

    // Initialize filter from URL
    setFilter({
      reviewer: queryParams["reviewer"],
      clientName: queryParams["clientName"],
    });

    // eslint-disable-next-line
  }, [location]);

  const handleClearFilters = () => {
    GridHelper.handleSearch(filterInitialState, location, navigate);
  };

  let displayColumns = ["reviewer", "reviewedOn"];
  if (props.user.isSuperUser) displayColumns.push("clientName");

  const data = broadcastMessageReview.paginatedResult?.data.map((m) => {
    return {
      ...m,
      reviewedOn: moment(Utils.convertToDateTime(m.reviewedOn)).fromNow(),
    };
  });

  const loading = broadcastMessage.loading || broadcastMessageReview.loading;

  return (
    <>
      <Title
        text="Broadcast Messages Reviews"
        icon={<FaEye className="mb-1" />}
      />

      {!loading && (
        <>
          <Subtitle
            text={`Reviews of Broadcast Message '${broadcastMessage.paginatedResult?.data[0].title}'`}
            icon={<GiMegaphone className="mb-1" />}
          />

          <BroadcastMessageReviewGridFilter
            filter={filter}
            onClearFilters={handleClearFilters}
          />
        </>
      )}

      <Grid
        data={data}
        displayColumns={displayColumns}
        idColumn={"messageId"}
        actions={actions}
        pageNumber={broadcastMessageReview.paginatedResult?.pageNumber ?? 0}
        totalPages={broadcastMessageReview.paginatedResult?.totalPages ?? 0}
        loading={loading}
        onPageChange={GridHelper.handlePageClick}
        onSort={GridHelper.handleSortClick}
      ></Grid>
    </>
  );
}

export default BroadcastMessageReviewGrid;
