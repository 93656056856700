import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";

function Subtitle({ text, icon }) {
  return (
    <h5 className="mb-3">
      {icon} {text}
      <Link
        to={-1}
        className="float-end text-secondary fs-6 text-decoration-none"
      >
        <IoMdArrowRoundBack className="me-2 mb-1" />
        Go back
      </Link>
    </h5>
  );
}
export default Subtitle;
