export const clientConstants = {
  GET_CLIENT_MENU_REQUEST: "GET_CLIENT_MENU_REQUEST",
  GET_CLIENT_MENU_SUCCESS: "GET_CLIENT_MENU_SUCCESS",
  GET_CLIENT_MENU_FAILURE: "GET_CLIENT_MENU_FAILURE",
  GET_CLIENT_GRID_REQUEST: "GET_CLIENT_GRID_REQUEST",
  GET_CLIENT_GRID_SUCCESS: "GET_CLIENT_GRID_SUCCESS",
  GET_CLIENT_GRID_FAILURE: "GET_CLIENT_GRID_FAILURE",
  CREATE_REQUEST: "CREATE_CLIENT_REQUEST",
  CREATE_SUCCESS: "CREATE_CLIENT_SUCCESS",
  CREATE_FAILURE: "CREATE_CLIENT_FAILURE",
  RENEW_REQUEST: "RENEW_CLIENT_REQUEST",
  RENEW_SUCCESS: "RENEW_CLIENT_SUCCESS",
  RENEW_FAILURE: "RENEW_CLIENT_FAILURE",
  CALLER_CLIENT_MENU: "CALLER_CLIENT_MENU",
  CALLER_CLIENT_GRID: "CALLER_CLIENT_GRID",
};
