import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import LeftMenu from "./LeftMenu";
import InviteModal from "../user/InviteModal";
import BroadcastMessagesForUser from "../broadcastMessage/BroadcastMessagesForUser";

function Layout(props) {
  return (
    <div className="overflow-hidden h-100">
      <Header user={props.user} />
      <div className="d-flex h-100">
        <LeftMenu user={props.user} />
        <div className="py-3 px-4 me-0 mb-5 overflow-auto w-100">
          <BroadcastMessagesForUser user={props.user} />
          <Outlet />
        </div>
      </div>
      <InviteModal user={props.user} />
    </div>
  );
}

export default Layout;
