import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { broadcastMessageActions } from "../../actions/broadcastMessage.actions";
import { broadcastMessageReviewActions } from "../../actions/broadcastMessageReview.actions";
import { Alert, Button } from "react-bootstrap";

function BroadcastMessagesForUser({ user }) {
  const dispatch = useDispatch();
  const userMessages = useSelector(
    (state) => state.broadcastMessage.userMessages
  );
  const [readMessages, setReadMessages] = useState([]);

  useEffect(() => {
    if (user) {
      const requestParameters = {
        userId: user.userId,
        clientId: user.clientId,
        clientGuid: user.clientGuid,
      };

      dispatch(broadcastMessageActions.forUser(requestParameters));
    }
    // eslint-disable-next-line
  }, []);

  const handleMarkAsRead = (messageId) => {
    setReadMessages([...readMessages, messageId]);

    dispatch(
      broadcastMessageReviewActions.create({
        messageId,
        userId: user.userId,
        clientId: user.clientId,
        clientGuid: user.clientGuid,
      })
    );
  };

  return (
    <div>
      {userMessages &&
        userMessages.length > 0 &&
        userMessages
          .filter((message) => !readMessages.includes(message.messageId))
          .map((message) => (
            <Alert key={message.messageId} variant={message.variant || "info"}>
              {message.title && (
                <Alert.Heading>
                  <small>{message.title}</small>
                </Alert.Heading>
              )}
              <p>{message.content}</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => handleMarkAsRead(message.messageId)}
                >
                  Mark as Read
                </Button>
              </div>
            </Alert>
          ))}
    </div>
  );
}

export default BroadcastMessagesForUser;
