import { broadcastMessageConstants } from "../constants/broadcastMessage.constants";

export function broadcastMessage(state = {}, action) {
  switch (action.type) {
    case broadcastMessageConstants.GET_BROADCAST_MESSAGES_REQUEST:
    case broadcastMessageConstants.CREATE_BROADCAST_MESSAGE_REQUEST:
    case broadcastMessageConstants.UPDATE_BROADCAST_MESSAGE_REQUEST:
    case broadcastMessageConstants.GET_BROADCAST_MESSAGE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        request: action.payload,
        error: false,
        success: false,
      };

    case broadcastMessageConstants.GET_BROADCAST_MESSAGES_FOR_USER_REQUEST:
      return {
        ...state,
        loadingUsers: true,
        request: action.payload,
        error: false,
        success: false,
      };

    case broadcastMessageConstants.GET_BROADCAST_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        paginatedResult: action.payload,
      };

    case broadcastMessageConstants.GET_BROADCAST_MESSAGE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };

    case broadcastMessageConstants.GET_BROADCAST_MESSAGES_FOR_USER_SUCCESS:
      return {
        ...state,
        loadingUsers: false,
        userMessages: action.payload,
      };

    case broadcastMessageConstants.CREATE_BROADCAST_MESSAGE_SUCCESS:
    case broadcastMessageConstants.UPDATE_BROADCAST_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };

    case broadcastMessageConstants.DELETE_BROADCAST_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case broadcastMessageConstants.DELETE_BROADCAST_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case broadcastMessageConstants.CREATE_BROADCAST_MESSAGE_FAILURE:
    case broadcastMessageConstants.GET_BROADCAST_MESSAGES_FAILURE:
    case broadcastMessageConstants.GET_BROADCAST_MESSAGE_BY_ID_FAILURE:
    case broadcastMessageConstants.DELETE_BROADCAST_MESSAGE_FAILURE:
    case broadcastMessageConstants.UPDATE_BROADCAST_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case broadcastMessageConstants.GET_BROADCAST_MESSAGES_FOR_USER_FAILURE:
      return {
        ...state,
        loadingUsers: false,
        error: true,
      };

    default:
      return state;
  }
}
