import { http } from "../api/http";
import Utils from "../utils";

export const runbookStepService = {
  get,
};

function get(requestParameters) {
  let url = `/runbookstep?${Utils.objectToQueryString(requestParameters)}`;

  return http.get(url).then((response) => {
    return response;
  });
}
