import { runbookSchedulerService } from "../services/runbookScheduler.service";
import { schedulerConstants } from "../constants/scheduler.constants";
import { dashboardConstants } from "../constants/dashboard.constants";

export const runbookSchedulerActions = {
  get,
  getNextExecutions,
  create,
  remove,
  update,
};

function get(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    runbookSchedulerService.get(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return { type: schedulerConstants.GET_REQUEST, payload: requestParameters };
  }
  function success(paginatedResult) {
    return { type: schedulerConstants.GET_SUCCESS, payload: paginatedResult };
  }
  function failure() {
    return { type: schedulerConstants.GET_FAILURE };
  }
}

function getNextExecutions(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    runbookSchedulerService.getNextExecutions(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: dashboardConstants.GET_NEXT_EXECUTIONS_REQUEST,
      payload: requestParameters,
    };
  }
  function success(paginatedResult) {
    return {
      type: dashboardConstants.GET_NEXT_EXECUTIONS_SUCCESS,
      payload: paginatedResult,
    };
  }
  function failure() {
    return { type: dashboardConstants.GET_NEXT_EXECUTIONS_FAILURE };
  }
}

function create(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    runbookSchedulerService.create(requestParameters).then(
      (response) => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: schedulerConstants.CREATE_REQUEST,
      payload: requestParameters,
    };
  }
  function success() {
    return { type: schedulerConstants.CREATE_SUCCESS };
  }
  function failure() {
    return { type: schedulerConstants.CREATE_FAILURE };
  }
}

function remove(requestParameters) {
  return (dispatch) => {
    dispatch(request());

    runbookSchedulerService.remove(requestParameters).then(
      (response) => {
        window.location.reload();
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request() {
    return {
      type: schedulerConstants.DELETE_REQUEST,
    };
  }
  function failure() {
    return { type: schedulerConstants.DELETE_FAILURE };
  }
}

function update(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    runbookSchedulerService.update(requestParameters).then(
      (response) => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: schedulerConstants.UPDATE_REQUEST,
      payload: requestParameters,
    };
  }
  function success() {
    return { type: schedulerConstants.UPDATE_SUCCESS };
  }
  function failure() {
    return { type: schedulerConstants.UPDATE_FAILURE };
  }
}
