import React, { useEffect, useState } from "react";
import Title from "../common/Title";
import { CiTextAlignJustify } from "react-icons/ci";
import Utils from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import { clientVariableLineActions } from "../../actions/clientVariableLine.actions";
import { useDispatch, useSelector } from "react-redux";
import PlaceHolderLines from "../common/PlaceHolderLines";
import useClientVariableTitle from "../../hooks/clientVariableTitle";
import Subtitle from "../common/Subtitle";
import { LuVariable } from "react-icons/lu";

function VariableLineForm(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [variableLineId, setVariableLineId] = useState("");
  const [variableKey, setVariableKey] = useState("");
  const [variableValue, setVariableValue] = useState("");
  const [variableId, setVariableId] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { clientVariableTitle, clientVariableTitleLoading } =
    useClientVariableTitle(variableId);

  const clientVariableLineReducer = useSelector(
    (state) => state.clientVariableLine
  );

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);

    if (queryParams["variableLineId"]) {
      queryParams["clientId"] = props.user.clientId;
      queryParams["clientGuid"] = props.user.clientGuid;
      setVariableLineId(queryParams["variableLineId"]);
      dispatch(clientVariableLineActions.get(queryParams));
    } else {
      setVariableLineId("");
      setVariableKey("");
      setVariableValue("");
      setVariableId(queryParams["variableId"]);
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (
      variableLineId &&
      clientVariableLineReducer.paginatedResult &&
      clientVariableLineReducer.paginatedResult.data &&
      clientVariableLineReducer.paginatedResult.data[0]
    ) {
      const data = clientVariableLineReducer.paginatedResult.data[0];
      setVariableId(data.variableId || "");
      setVariableKey(data.variableKey || "");
      setVariableValue(data.variableValue || "");
    }
    // eslint-disable-next-line
  }, [clientVariableLineReducer]);

  useEffect(() => {
    if (clientVariableLineReducer.success) {
      navigate(`/variable/line?variableId=${variableId}`);
    }
    // eslint-disable-next-line
  }, [clientVariableLineReducer.success]);

  const handleSaveVariableLine = () => {
    setSubmitted(true);

    if (variableKey) {
      if (variableLineId) {
        dispatch(
          clientVariableLineActions.update({
            variableId,
            variableLineId,
            variableKey,
            variableValue,
            clientId: props.user.clientId,
            clientGuid: props.user.clientGuid,
          })
        );
      } else if (variableId) {
        dispatch(
          clientVariableLineActions.create({
            variableId,
            variableKey,
            variableValue,
            clientId: props.user.clientId,
            clientGuid: props.user.clientGuid,
          })
        );
      }
    }
  };

  const operation = !variableLineId ? "Add" : "Update";
  const loading =
    clientVariableTitleLoading || clientVariableLineReducer.loading;

  return (
    <>
      <Title
        text={`${operation} Client Variable Line`}
        icon={<CiTextAlignJustify className="mb-1" />}
      />

      {!loading && (
        <Subtitle
          text={clientVariableTitle}
          icon={<LuVariable className="mb-1" />}
        />
      )}

      {loading && <PlaceHolderLines count={4} />}

      {!loading && (
        <>
          {!clientVariableLineReducer.success && (
            <>
              {clientVariableLineReducer.error && (
                <Alert variant={"danger"}>
                  An error has occurred. Please try again later.
                </Alert>
              )}

              {variableLineId && (
                <Form.Group controlId="formVariableLineId" className="mb-3">
                  <Form.Label>Variable Line Id</Form.Label>
                  <Form.Control
                    type="text"
                    value={variableLineId}
                    onChange={(e) => setVariableLineId(e.target.value)}
                    disabled
                  />
                </Form.Group>
              )}

              <Form.Group controlId="formVariableKey" className="mb-3">
                <Form.Label>Variable Key</Form.Label>
                <Form.Control
                  type="text"
                  value={variableKey}
                  onChange={(e) => setVariableKey(e.target.value)}
                  className={submitted && !variableKey ? "is-invalid" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  <small>Please provide a variable key.</small>
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formVariableValue" className="mb-3">
                <Form.Label>Variable Value</Form.Label>
                <Form.Control
                  type="text"
                  value={variableValue}
                  onChange={(e) => setVariableValue(e.target.value)}
                />
              </Form.Group>

              <Button
                variant="outline-secondary"
                type="submit"
                className="me-3 mt-3"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                type="submit"
                className="mt-3"
                onClick={handleSaveVariableLine}
                disabled={clientVariableLineReducer.loading}
              >
                {!clientVariableLineReducer.loading ? (
                  <>{operation} Client Variable Line</>
                ) : (
                  <Spinner size="sm" />
                )}
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
}

export default VariableLineForm;
