export const runbookConnectorConstants = {
  GET_REQUEST: "GET_RUNBOOK_CONNECTOR_REQUEST",
  GET_SUCCESS: "GET_RUNBOOK_CONNECTOR_SUCCESS",
  GET_FAILED: "GET_RUNBOOK_CONNECTOR_FAILED",
  CREATE_REQUEST: "CREATE_RUNBOOK_CONNECTOR_REQUEST",
  CREATE_SUCCESS: "CREATE_RUNBOOK_CONNECTOR_SUCCESS",
  CREATE_FAILED: "CREATE_RUNBOOK_CONNECTOR_FAILED",
  DELETE_REQUEST: "DELETE_RUNBOOK_CONNECTOR_REQUEST",
  DELETE_SUCCESS: "DELETE_RUNBOOK_CONNECTOR_SUCCESS",
  DELETE_FAILED: "DELETE_RUNBOOK_CONNECTOR_FAILED",
};
