import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Utils from "../../utils";
import { runbookExecutionHistoryActions } from "../../actions/runbookExecutionHistory.actions";
import Grid from "../common/Grid";
import Title from "../common/Title";
import { CiBoxList } from "react-icons/ci";
import { MdError } from "react-icons/md";
import Subtitle from "../common/Subtitle";
import useRunbookTitle from "../../hooks/runbookTitle";
import { JsonView } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import GridHelper from "../helpers/gridHelper";

function RunbookExecutionHistoryLineGrid(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const runbookExecutionHistoryLineReducer = useSelector(
    (state) => state.runbookExecutionHistoryLine
  );

  const actions = [];

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams["clientId"] = props.user.clientId;
    queryParams["clientGuid"] = props.user.clientGuid;

    dispatch(runbookExecutionHistoryActions.getHistoryLine(queryParams));
    // eslint-disable-next-line
  }, [location]);

  let displayColumns = [
    "stepId",
    "recordNo",
    "errorMessage",
    "sourceData",
    "transDateTime",
  ];

  const data = runbookExecutionHistoryLineReducer.paginatedResult?.data.map(
    (l) => {
      return {
        ...l,
        transDateTime: Utils.convertToDateTime(l.transDateTime),
        sourceData: l.sourceData ? (
          <div style={{ maxWidth: "400px" }}>
            <JsonView
              data={JSON.parse(l.sourceData)}
              shouldExpandNode={() => {}}
            />
          </div>
        ) : (
          ""
        ),
      };
    }
  );
  const historyLine = data?.[0];
  const { runbookTitle, runbookTitleLoading } = useRunbookTitle(
    historyLine?.runbookId
  );

  const executionId = Utils.getQueryStringParams(location)["executionId"];

  return (
    <>
      <Title
        text="Execution History Lines"
        icon={<MdError className="mb-1" />}
      />
      {!runbookTitleLoading && !runbookExecutionHistoryLineReducer.loading && (
        <Subtitle
          text={`Execution of ${runbookTitle} (${executionId})`}
          icon={<CiBoxList className="mb-1" />}
        />
      )}

      <Grid
        data={data}
        displayColumns={displayColumns}
        actions={actions}
        pageNumber={
          runbookExecutionHistoryLineReducer.paginatedResult?.pageNumber ?? 0
        }
        totalPages={
          runbookExecutionHistoryLineReducer.paginatedResult?.totalPages ?? 0
        }
        loading={
          runbookExecutionHistoryLineReducer.loading || runbookTitleLoading
        }
        onPageChange={GridHelper.handlePageClick}
        onSort={GridHelper.handleSortClick}
      ></Grid>
    </>
  );
}

export default RunbookExecutionHistoryLineGrid;
