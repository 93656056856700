function ChartKey(props) {
  return (
    <div className="mt-3">
      {props.data.map((d) => {
        return (
          <div key={d.title} className="my-2">
            <div
              className="me-2 float-start"
              style={{
                backgroundColor: d.color,
                width: "20px",
                height: "20px",
              }}
            ></div>
            <small>{d.title}</small>
            <div className="clearfix"></div>
          </div>
        );
      })}
    </div>
  );
}

export default ChartKey;
