import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../actions/user.actions";
import Utils from "../../utils";
import { FaPerson } from "react-icons/fa6";
import Title from "../common/Title";
import PlaceHolderLines from "../common/PlaceHolderLines";

function UserForm(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const userReducer = useSelector((state) => state.user);

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);
    if (queryParams["userId"]) {
      setUserId(queryParams["userId"]);
      dispatch(userActions.getById(queryParams["userId"]));
    } else {
      setUserId("");
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (userId && userReducer.userById) {
      const user = userReducer.userById;
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setIsAdmin(user.isAdmin);
      setIsSuperUser(user.isSuperUser);
    }
    // eslint-disable-next-line
  }, [userReducer]);

  useEffect(() => {
    if (userReducer.updateSuccess) {
      navigate("/user");
    }
    // eslint-disable-next-line
  }, [userReducer.updateSuccess]);

  const handleSaveUser = () => {
    setSubmitted(true);

    if (firstName && lastName) {
      const userData = {
        firstName,
        lastName,
        isAdmin,
        isSuperUser,
      };

      if (userId) {
        dispatch(userActions.update({ ...userData, userId }));
      }
    }
  };

  return (
    <>
      <Title text={"Update User"} icon={<FaPerson className="mb-1" />} />

      {userReducer.loading && <PlaceHolderLines count={4} />}

      {!userReducer.loading && (
        <>
          {userReducer.error && (
            <Alert variant="danger">
              An error has occurred. Please try again later.
            </Alert>
          )}

          <Form.Group controlId="formFirstName" className="mb-3">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className={submitted && !firstName ? "is-invalid" : ""}
            />
            <Form.Control.Feedback type="invalid">
              <small>Please provide a first name.</small>
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formLastName" className="mb-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className={submitted && !lastName ? "is-invalid" : ""}
            />
            <Form.Control.Feedback type="invalid">
              <small>Please provide a last name.</small>
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formIsAdmin" className="mb-3">
            <Form.Check
              type="checkbox"
              label="Is Admin"
              checked={isAdmin}
              onChange={(e) => setIsAdmin(e.target.checked)}
            />
          </Form.Group>

          <Form.Group controlId="formIsSuperUser" className="mb-3">
            <Form.Check
              type="checkbox"
              label="Is SuperUser"
              checked={isSuperUser}
              onChange={(e) => setIsSuperUser(e.target.checked)}
            />
          </Form.Group>

          <Button
            variant="outline-secondary"
            type="button"
            className="me-3"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            type="button"
            onClick={handleSaveUser}
            disabled={userReducer.loading}
          >
            {!userReducer.loading ? <>Update User</> : <Spinner size="sm" />}
          </Button>
        </>
      )}
    </>
  );
}

export default UserForm;
