import { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Utils from "../../utils";
import PlaceHolderLines from "./PlaceHolderLines";

function DetailsModal(props) {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(props.data);

    // eslint-disable-next-line
  }, [props.data]);

  return (
    <Modal show={props.show} onHide={props.onClose} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!props.loading ? (
          <>
            {data &&
              Object.entries(data).map(
                ([key, value], i) =>
                  (value || value === 0) && (
                    <Container key={i}>
                      <Row
                        key={key}
                        className={`${i === 0 ? "pb-3" : "py-3"} border-bottom`}
                      >
                        <Col md={4}>
                          <b>{Utils.fixCamelCase(key)}</b>
                        </Col>
                        <Col>{value}</Col>
                      </Row>
                    </Container>
                  )
              )}
          </>
        ) : (
          <>
            <PlaceHolderLines count={6} />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default DetailsModal;
