import React from "react";
import { CiCloudOff } from "react-icons/ci";

function NoPage() {
  return (
    <div className="centered-div">
      <div className="text-center mt-5">
        <CiCloudOff size={180} className="text-muted" />
        <h2 className="fw-bold">Page not found</h2>
        Please, try with a different URL
      </div>
    </div>
  );
}

export default NoPage;
