import React, { useEffect, useState } from "react";
import Title from "../common/Title";
import { GiMegaphone } from "react-icons/gi";
import Utils from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { broadcastMessageActions } from "../../actions/broadcastMessage.actions";
import PlaceHolderLines from "../common/PlaceHolderLines";
import VariantDropdown from "../common/VariantDropdown";

function BroadcastMessageForm(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [messageId, setMessageId] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [variant, setVariant] = useState("primary");
  const [displayStartDate, setDisplayStartDate] = useState(null);
  const [displayEndDate, setDisplayEndDate] = useState(null);
  const broadcastMessageReducer = useSelector(
    (state) => state.broadcastMessage
  );

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);
    if (queryParams["messageId"]) {
      queryParams["clientId"] = props.user.clientId;
      queryParams["clientGuid"] = props.user.clientGuid;
      setMessageId(queryParams["messageId"]);
      dispatch(broadcastMessageActions.getById(queryParams));
    } else {
      setMessageId("");
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (messageId && broadcastMessageReducer.message) {
      const msg = broadcastMessageReducer.message;
      setTitle(msg.title);
      setContent(msg.content);
      setVariant(msg.variant);
      setDisplayStartDate(Utils.formatDateToInput(msg.displayStartDate));
      setDisplayEndDate(Utils.formatDateToInput(msg.displayEndDate));
    }
    // eslint-disable-next-line
  }, [broadcastMessageReducer]);

  useEffect(() => {
    if (broadcastMessageReducer.success) {
      navigate("/messages");
    }
    // eslint-disable-next-line
  }, [broadcastMessageReducer.success]);

  const handleSaveMessage = () => {
    const messageData = {
      title,
      content,
      variant,
      displayStartDate: displayStartDate
        ? `${displayStartDate}T00:00:00`
        : null,
      displayEndDate: displayEndDate ? `${displayEndDate}T23:59:59` : null,
      clientId: props.user.clientId ? props.user.clientId : null,
      clientGuid: props.user.clientGuid ? props.user.clientGuid : null,
    };

    if (messageId) {
      dispatch(
        broadcastMessageActions.update({
          ...messageData,
          messageId,
        })
      );
    } else {
      dispatch(broadcastMessageActions.create(messageData));
    }
  };

  const operation = !messageId ? "Add" : "Update";

  return (
    <>
      <Title
        text={`${operation} Broadcast Message`}
        icon={<GiMegaphone className="mb-1" />}
      />

      {broadcastMessageReducer.loading && <PlaceHolderLines count={4} />}

      {!broadcastMessageReducer.loading && (
        <>
          {!broadcastMessageReducer.success && (
            <>
              {!broadcastMessageReducer.loading &&
                broadcastMessageReducer.error && (
                  <Alert variant={"danger"}>
                    An error has occurred. Please try again later.
                  </Alert>
                )}

              <Form.Group controlId="formTitle" className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formContent" className="mb-3">
                <Form.Label>Content</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formVariant" className="mb-3">
                <Form.Label>Variant</Form.Label>
                <VariantDropdown
                  value={variant}
                  onChange={(e) => setVariant(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formDisplayStartDate" className="mb-3">
                <Form.Label>Display Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={displayStartDate ?? ""}
                  onChange={(e) => setDisplayStartDate(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="formDisplayEndDate" className="mb-3">
                <Form.Label>Display End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={displayEndDate ?? ""}
                  onChange={(e) => setDisplayEndDate(e.target.value)}
                />
              </Form.Group>

              {props.user.isSuperUser && (
                <p>
                  <b>
                    {!props.user.clientId ? (
                      <>
                        This message will be displayed to ALL users in the
                        portal.
                      </>
                    ) : (
                      <>
                        This message will be displayed ONLY to users from{" "}
                        {props.user.clientName}.
                      </>
                    )}
                  </b>
                </p>
              )}

              <Button
                variant="outline-secondary"
                type="button"
                className="me-3 mt-3"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                type="button"
                className="mt-3"
                onClick={handleSaveMessage}
                disabled={broadcastMessageReducer.loading}
              >
                {!broadcastMessageReducer.loading ? (
                  <>{operation} Broadcast Message</>
                ) : (
                  <Spinner size="sm" />
                )}
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
}

export default BroadcastMessageForm;
