import { runbookExecutionHistoryService } from "../services/runbookExecutionHistory.service";
import { runbookExecutionHistoryConstants } from "../constants/runbookExecutionHistory.constants";
import { runbookExecutionHistoryLineConstants } from "../constants/runbookExecutionHistoryLine.constants";
import { dashboardConstants } from "../constants/dashboard.constants";

export const runbookExecutionHistoryActions = {
  get,
  getHistoryLine,
  getLatestExecutions,
};

function get(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    runbookExecutionHistoryService.get(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: runbookExecutionHistoryConstants.GET_REQUEST,
      payload: requestParameters,
    };
  }
  function success(paginatedResult) {
    return {
      type: runbookExecutionHistoryConstants.GET_SUCCESS,
      payload: paginatedResult,
    };
  }
  function failure() {
    return { type: runbookExecutionHistoryConstants.GET_FAILURE };
  }
}

function getHistoryLine(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    runbookExecutionHistoryService.getHistoryLine(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: runbookExecutionHistoryLineConstants.GET_REQUEST,
      payload: requestParameters,
    };
  }
  function success(paginatedResult) {
    return {
      type: runbookExecutionHistoryLineConstants.GET_SUCCESS,
      payload: paginatedResult,
    };
  }
  function failure() {
    return { type: runbookExecutionHistoryLineConstants.GET_FAILURE };
  }
}

function getLatestExecutions(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    runbookExecutionHistoryService.getLatestExecutions(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: dashboardConstants.GET_LATEST_EXECUTIONS_REQUEST,
      payload: requestParameters,
    };
  }
  function success(paginatedResult) {
    return {
      type: dashboardConstants.GET_LATEST_EXECUTIONS_SUCCESS,
      payload: paginatedResult,
    };
  }
  function failure() {
    return { type: dashboardConstants.GET_LATEST_EXECUTIONS_FAILURE };
  }
}
