import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Collapse } from "react-bootstrap";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import GridHelper from "../helpers/gridHelper";

function SchedulerGridFilter(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    if (props.filter) setFilter(props.filter);
    const filterIsEmpty = Object.values(props.filter).every(
      (x) => x === null || x === "" || x === undefined
    );
    setExpanded(!filterIsEmpty);

    // eslint-disable-next-line
  }, [props]);

  const onKeyDown = (e) => {
    if (e.key === "Enter") GridHelper.handleSearch(filter, location, navigate);
  };

  return (
    <div className="bg-light border p-3 mb-3 rounded" onKeyDown={onKeyDown}>
      <Collapse in={expanded}>
        <div>
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Scheduler Id</Form.Label>
                <Form.Control
                  value={filter.schedulerId ?? ""}
                  onChange={(e) =>
                    setFilter({ ...filter, schedulerId: e.target.value })
                  }
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Runbook Id</Form.Label>
                <Form.Control
                  value={filter.runbookId ?? ""}
                  onChange={(e) =>
                    setFilter({ ...filter, runbookId: e.target.value })
                  }
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Runbook Name</Form.Label>
                <Form.Control
                  value={filter.runbookName ?? ""}
                  onChange={(e) =>
                    setFilter({ ...filter, runbookName: e.target.value })
                  }
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Next Execution to</Form.Label>
                <Form.Control
                  value={filter.nextExecution ?? ""}
                  onChange={(e) =>
                    setFilter({ ...filter, nextExecution: e.target.value })
                  }
                  type="date"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Cron Error</Form.Label>
                <Form.Select
                  value={filter.cronError ?? ""}
                  onChange={(e) =>
                    setFilter({ ...filter, cronError: e.target.value })
                  }
                >
                  <option value={""}>-</option>
                  <option value={true}>Error</option>
                  <option value={false}>No Error</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Created By</Form.Label>
                <Form.Control
                  value={filter.createdBy ?? ""}
                  onChange={(e) =>
                    setFilter({ ...filter, createdBy: e.target.value })
                  }
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="link"
            size="sm"
            className="text-decoration-none p-0 m-0 float-start mt-4 fw-bold"
            onClick={() => setExpanded(!expanded)}
          >
            Click here to collapse filters <FaAngleUp size={13} />
          </Button>
          <Button
            variant="primary"
            className="float-end mt-3"
            onClick={() => GridHelper.handleSearch(filter, location, navigate)}
          >
            Search
          </Button>
          <Button
            variant="secondary"
            className="float-end mt-3 me-2"
            onClick={() => props.onClearFilters()}
          >
            Clear filters
          </Button>
          <div className="clearfix" />
        </div>
      </Collapse>
      {!expanded && (
        <Button
          variant="link"
          size="sm"
          className="text-decoration-none p-0 m-0 fw-bold"
          onClick={() => setExpanded(!expanded)}
        >
          Click here to expand filters <FaAngleDown size={13} />
        </Button>
      )}
    </div>
  );
}

export default SchedulerGridFilter;
