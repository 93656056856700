import { http } from "../api/http";
import Utils from "../utils";

export const runbookSchedulerService = {
  get,
  getNextExecutions,
  create,
  remove,
  update,
};

function get(requestParameters) {
  let url = `/runbook/scheduler?${Utils.objectToQueryString(
    requestParameters
  )}`;

  return http.get(url).then((response) => {
    return response;
  });
}

function getNextExecutions(requestParameters) {
  let url = `/runbook/scheduler/getnextexecutions?${Utils.objectToQueryString(
    requestParameters
  )}`;

  return http.get(url).then((response) => {
    return response;
  });
}

function create(requestBody) {
  let url = `/runbook/scheduler/create`;

  return http.post(url, JSON.stringify(requestBody)).then((response) => {
    return response;
  });
}

function remove(requestParameters) {
  let url = `/runbook/scheduler/delete?${Utils.objectToQueryString(
    requestParameters
  )}`;

  return http.delete(url).then((response) => {
    return response;
  });
}

function update(requestBody) {
  let url = `/runbook/scheduler/update`;

  return http.patch(url, JSON.stringify(requestBody)).then((response) => {
    return response;
  });
}
