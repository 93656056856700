import { userService } from "../services/user.service";
import { userConstants } from "../constants/user.constants";
import Cookies from "js-cookie";
import Utils from "../utils";

export const userActions = {
  login,
  logout,
  changeClient,
  recoverPasswordInit,
  recoverPassword,
  validateCode,
  updatePassword,
  create,
  get,
  getById,
  update,
  remove,
};

function login(email, password) {
  return (dispatch) => {
    dispatch(request());

    userService.login(email, password).then(
      (response) => {
        dispatch(success());
        Cookies.set("user", JSON.stringify(response.data));
        Utils.refreshPage();
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: userConstants.LOGIN_REQUEST };
  }
  function success() {
    return { type: userConstants.LOGIN_SUCCESS };
  }
  function failure() {
    return { type: userConstants.LOGIN_FAILURE };
  }
}

function logout() {
  return (dispatch) => {
    dispatch(request());

    try {
      Cookies.remove("user");
      dispatch(success());
      Utils.refreshPage();
    } catch {
      dispatch(failure());
    }
  };

  function request() {
    return { type: userConstants.LOGOUT_REQUEST };
  }
  function success() {
    return { type: userConstants.LOGOUT_SUCCESS };
  }
  function failure() {
    return { type: userConstants.LOGOUT_FAILURE };
  }
}

function changeClient(clientId, clientGuid, clientName) {
  const user = JSON.parse(Cookies.get("user"));
  user.clientId = clientId;
  user.clientGuid = clientGuid;
  user.clientName = clientName;
  Cookies.set("user", JSON.stringify(user));
  window.location = window.location.href.split("?")[0];
}

function recoverPasswordInit() {
  return (dispatch) => {
    dispatch(request());
  };

  function request() {
    return { type: userConstants.RECOVER_PASSWORD_INIT };
  }
}

function recoverPassword(email) {
  return (dispatch) => {
    dispatch(request());

    userService.recoverPassword(email).then(
      (response) => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: userConstants.RECOVER_PASSWORD_REQUEST };
  }
  function success() {
    return { type: userConstants.RECOVER_PASSWORD_SUCCESS };
  }
  function failure() {
    return { type: userConstants.RECOVER_PASSWORD_FAILURE };
  }
}

function validateCode(email, code) {
  return (dispatch) => {
    dispatch(request());

    userService.validateCode(email, code).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: userConstants.VERIFY_CODE_REQUEST };
  }
  function success(data) {
    return { type: userConstants.VERIFY_CODE_SUCCESS, payload: data.token };
  }
  function failure() {
    return { type: userConstants.VERIFY_CODE_FAILURE };
  }
}

function updatePassword(token, newPassword) {
  return (dispatch) => {
    dispatch(request());

    userService.updatePassword(token, newPassword).then(
      (response) => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: userConstants.CHANGE_PASSWORD_REQUEST };
  }
  function success() {
    return { type: userConstants.CHANGE_PASSWORD_SUCCESS, payload: {} };
  }
  function failure() {
    return { type: userConstants.CHANGE_PASSWORD_FAILURE };
  }
}

function create(
  token,
  firstName,
  lastName,
  email,
  clientId,
  clientGuid,
  password
) {
  return (dispatch) => {
    dispatch(request());

    userService
      .create(token, firstName, lastName, email, clientId, clientGuid, password)
      .then(
        (response) => {
          dispatch(success());
        },
        (error) => {
          dispatch(failure());
        }
      );
  };

  function request() {
    return { type: userConstants.CREATE_REQUEST };
  }
  function success() {
    return { type: userConstants.CREATE_SUCCESS };
  }
  function failure() {
    return { type: userConstants.CREATE_FAILURE };
  }
}

function get(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    userService.get(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: userConstants.GET_REQUEST,
      payload: requestParameters,
    };
  }
  function success(users) {
    return {
      type: userConstants.GET_SUCCESS,
      payload: users,
    };
  }
  function failure() {
    return { type: userConstants.GET_FAILURE };
  }
}

function getById(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    userService.getById(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: userConstants.GET_BY_ID_REQUEST,
      payload: requestParameters,
    };
  }
  function success(user) {
    return {
      type: userConstants.GET_BY_ID_SUCCESS,
      payload: user,
    };
  }
  function failure() {
    return { type: userConstants.GET_BY_ID_FAILURE };
  }
}

function update(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    userService.update(requestParameters).then(
      (response) => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: userConstants.UPDATE_REQUEST,
      payload: requestParameters,
    };
  }
  function success() {
    return { type: userConstants.UPDATE_SUCCESS };
  }
  function failure() {
    return { type: userConstants.UPDATE_FAILURE };
  }
}

function remove(requestParameters) {
  return (dispatch) => {
    dispatch(request());

    userService.remove(requestParameters).then(
      (response) => {
        window.location.reload();
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request() {
    return {
      type: userConstants.DELETE_REQUEST,
    };
  }
  function failure() {
    return { type: userConstants.DELETE_FAILURE };
  }
}
