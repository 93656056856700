import { broadcastMessageService } from "../services/broadcastMessage.service";
import { broadcastMessageConstants } from "../constants/broadcastMessage.constants";

export const broadcastMessageActions = {
  get,
  getById,
  create,
  remove,
  update,
  forUser,
};

function get(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    broadcastMessageService.get(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: broadcastMessageConstants.GET_BROADCAST_MESSAGES_REQUEST,
      payload: requestParameters,
    };
  }
  function success(paginatedResult) {
    return {
      type: broadcastMessageConstants.GET_BROADCAST_MESSAGES_SUCCESS,
      payload: paginatedResult,
    };
  }
  function failure() {
    return { type: broadcastMessageConstants.GET_BROADCAST_MESSAGES_FAILURE };
  }
}

function getById(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    broadcastMessageService.getById(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: broadcastMessageConstants.GET_BROADCAST_MESSAGE_BY_ID_REQUEST,
      payload: requestParameters,
    };
  }
  function success(message) {
    return {
      type: broadcastMessageConstants.GET_BROADCAST_MESSAGE_BY_ID_SUCCESS,
      payload: message,
    };
  }
  function failure() {
    return {
      type: broadcastMessageConstants.GET_BROADCAST_MESSAGE_BY_ID_FAILURE,
    };
  }
}

function create(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    broadcastMessageService.create(requestParameters).then(
      (response) => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: broadcastMessageConstants.CREATE_BROADCAST_MESSAGE_REQUEST,
      payload: requestParameters,
    };
  }
  function success() {
    return { type: broadcastMessageConstants.CREATE_BROADCAST_MESSAGE_SUCCESS };
  }
  function failure() {
    return { type: broadcastMessageConstants.CREATE_BROADCAST_MESSAGE_FAILURE };
  }
}

function remove(requestParameters) {
  return (dispatch) => {
    dispatch(request());

    broadcastMessageService.remove(requestParameters).then(
      (response) => {
        window.location.reload();
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request() {
    return {
      type: broadcastMessageConstants.DELETE_BROADCAST_MESSAGE_REQUEST,
    };
  }
  function failure() {
    return { type: broadcastMessageConstants.DELETE_BROADCAST_MESSAGE_FAILURE };
  }
}

function update(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    broadcastMessageService.update(requestParameters).then(
      (response) => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: broadcastMessageConstants.UPDATE_BROADCAST_MESSAGE_REQUEST,
      payload: requestParameters,
    };
  }
  function success() {
    return { type: broadcastMessageConstants.UPDATE_BROADCAST_MESSAGE_SUCCESS };
  }
  function failure() {
    return { type: broadcastMessageConstants.UPDATE_BROADCAST_MESSAGE_FAILURE };
  }
}

function forUser(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    broadcastMessageService.forUser(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: broadcastMessageConstants.GET_BROADCAST_MESSAGES_FOR_USER_REQUEST,
      payload: requestParameters,
    };
  }
  function success(userMessages) {
    return {
      type: broadcastMessageConstants.GET_BROADCAST_MESSAGES_FOR_USER_SUCCESS,
      payload: userMessages,
    };
  }
  function failure() {
    return {
      type: broadcastMessageConstants.GET_BROADCAST_MESSAGES_FOR_USER_FAILURE,
    };
  }
}
