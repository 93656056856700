export const schedulerConstants = {
  GET_REQUEST: "GET_SCHEDULER_REQUEST",
  GET_SUCCESS: "GET_SCHEDULER_SUCCESS",
  GET_FAILURE: "GET_SCHEDULER_FAILURE",
  CREATE_REQUEST: "CREATE_SCHEDULER_REQUEST",
  CREATE_SUCCESS: "CREATE_SCHEDULER_SUCCESS",
  CREATE_FAILURE: "CREATE_SCHEDULER_FAILURE",
  DELETE_REQUEST: "DELETE_SCHEDULER_REQUEST",
  DELETE_SUCCESS: "DELETE_SCHEDULER_SUCCESS",
  DELETE_FAILURE: "DELETE_SCHEDULER_FAILURE",
  UPDATE_REQUEST: "UPDATE_SCHEDULER_REQUEST",
  UPDATE_SUCCESS: "UPDATE_SCHEDULER_SUCCESS",
  UPDATE_FAILURE: "UPDATE_SCHEDULER_FAILURE",
};
