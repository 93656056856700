class Utils {
  static refreshPage() {
    window.location.reload();
  }

  static objectToQueryString = (obj) => {
    return Object.keys(obj)
      .filter((key) => encodeURIComponent(obj[key]))
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
      )
      .join("&");
  };

  static getQueryStringParams = (location) => {
    const searchParams = new URLSearchParams(location.search);
    const params = {};

    for (let [key, value] of searchParams.entries()) {
      params[key] = value;
    }

    return params;
  };

  static fixCamelCase = (input) => {
    return input
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Separate camelCase words
      .replace(/\b\w/g, (match) => match.toUpperCase()); // Capitalize the first letter of each word
  };

  static convertToDateTime(inputString) {
    if (!inputString) return "-";

    // Parse the input string as a Date object
    const inputDate = new Date(`${inputString}Z`);

    // Check if the input is a valid date
    if (isNaN(inputDate.getTime())) {
      return "Invalid date";
    }

    // Format the date in MM/DD/YYYY format
    const formattedDate = `${(inputDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${inputDate
      .getDate()
      .toString()
      .padStart(2, "0")}/${inputDate.getFullYear()}`;

    // Format the time in 12-hour format with AM/PM
    const hours = inputDate.getHours() % 12 || 12;
    const minutes = inputDate.getMinutes().toString().padStart(2, "0");
    const amPm = inputDate.getHours() >= 12 ? "PM" : "AM";
    const formattedTime = `${hours}:${minutes} ${amPm}`;

    // Combine the formatted date and time
    const usDateTime = `${formattedDate} ${formattedTime}`;

    return usDateTime;
  }

  static isEmailValid(email) {
    // Regular expression for basic email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  static formatDateToInput = (dateString) => {
    if (!dateString) return null;

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  static convertToDateOnly(dateTimeString) {
    if (!dateTimeString) return "-";

    // Parse the input string into a Date object
    const date = new Date(dateTimeString);

    // Add timezone offset to adjust for the correct local time
    const timezoneOffset = date.getTimezoneOffset() * 60000; // convert to milliseconds
    const adjustedDate = new Date(date.getTime() - timezoneOffset);

    // Format the adjusted date into mm/dd/yyyy
    const year = adjustedDate.getUTCFullYear();
    const month = String(adjustedDate.getUTCMonth() + 1).padStart(2, "0");
    const day = String(adjustedDate.getUTCDate()).padStart(2, "0");

    return `${month}/${day}/${year}`;
  }
}

export default Utils;
