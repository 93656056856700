import { http } from "../api/http";
import Utils from "../utils";

export const broadcastMessageService = {
  get,
  create,
  update,
  remove,
  getById,
  forUser,
};

async function get(requestParameters) {
  let url = `/broadcastmessage?${Utils.objectToQueryString(requestParameters)}`;

  const response = await http.get(url);
  return response;
}

async function create(requestBody) {
  let url = `/broadcastmessage/create`;

  const response = await http.post(url, JSON.stringify(requestBody));
  return response;
}

async function update(requestBody) {
  let url = `/broadcastmessage/update`;

  return await http.patch(url, JSON.stringify(requestBody)).then((response) => {
    return response;
  });
}

async function remove(requestParameters) {
  let url = `/broadcastmessage/delete?${Utils.objectToQueryString(
    requestParameters
  )}`;

  return await http.delete(url).then((response) => {
    return response;
  });
}

async function getById(requestParameters) {
  let url = `/broadcastmessage/byid?${Utils.objectToQueryString(
    requestParameters
  )}`;

  return await http.get(url).then((response) => {
    return response;
  });
}

async function forUser(requestParameters) {
  let url = `/broadcastmessage/foruser?${Utils.objectToQueryString(
    requestParameters
  )}`;

  return await http.get(url).then((response) => {
    return response;
  });
}
