import { PieChart } from "react-minimal-pie-chart";
import { Placeholder } from "react-bootstrap";
import ChartKey from "./ChartKey";
import NoResults from "../common/NoResults";

function Pie(props) {
  const colors = ["#BFE8FF", "#72ADCF", "#0075A4"];

  let data = [...props.data];

  data = data.filter((d) => d.value > 0);
  if (data.length > 0) {
    data.forEach((d, i) => (d.color = colors[i]));
    data = data.sort((d) => d.value).reverse();
  }

  return (
    <>
      {!props.loading && (
        <>
          {data.length > 0 && (
            <>
              <PieChart
                className="mt-2"
                data={data}
                animate={true}
                startAngle={270}
                label={({ dataEntry }) =>
                  Math.round(dataEntry.percentage) + "%"
                }
              />

              <ChartKey data={data} />
            </>
          )}
          {data.length === 0 && <NoResults noMarging={true} />}
        </>
      )}
      {props.loading && (
        <Placeholder as={"div"} style={{ height: "100%" }} animation="glow">
          <Placeholder xs={12} size="lg" style={{ height: "100%" }} />
        </Placeholder>
      )}
    </>
  );
}

export default Pie;
