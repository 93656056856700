import { dashboardConstants } from "../constants/dashboard.constants";

export function dashboard(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.GET_LATEST_EXECUTIONS_REQUEST:
      return {
        ...state,
        loadingLatestExecutions: true,
        requestLatestExecutions: action.payload,
      };
    case dashboardConstants.GET_LATEST_EXECUTIONS_SUCCESS:
      return {
        ...state,
        loadingLatestExecutions: false,
        paginatedLatestExecutionsResult: action.payload,
      };
    case dashboardConstants.GET_LATEST_EXECUTIONS_FAILURE:
      return {
        ...state,
        loadingLatestExecutions: false,
      };
    case dashboardConstants.GET_NEXT_EXECUTIONS_REQUEST:
      return {
        ...state,
        loadingNextExecutions: true,
        requestNextExecutions: action.payload,
      };
    case dashboardConstants.GET_NEXT_EXECUTIONS_SUCCESS:
      return {
        ...state,
        loadingNextExecutions: false,
        paginatedNextExecutionsResult: action.payload,
      };
    case dashboardConstants.GET_NEXT_EXECUTIONS_FAILURE:
      return {
        ...state,
        loadingNextExecutions: false,
      };
    case dashboardConstants.GET_KPIS_REQUEST:
      return {
        ...state,
        loadingKpis: true,
        requestKpis: action.payload,
      };
    case dashboardConstants.GET_KPIS_SUCCESS:
      return {
        ...state,
        loadingKpis: false,
        kpisResult: action.payload,
      };
    case dashboardConstants.GET_KPIS_FAILURE:
      return {
        ...state,
        loadingKpis: false,
      };
    default:
      return state;
  }
}
