import { http } from "../api/http";
import Utils from "../utils";

export const runbookService = {
  get,
  create,
  update,
  remove,
  execute,
  validate,
};

async function get(requestParameters) {
  let url = `/runbook?${Utils.objectToQueryString(requestParameters)}`;

  const response = await http.get(url);
  return response;
}

async function create(requestBody) {
  let url = `/runbook/create`;

  const response = await http.post(url, JSON.stringify(requestBody));
  return response;
}

async function update(requestBody) {
  let url = `/runbook/update`;

  return await http.patch(url, JSON.stringify(requestBody)).then((response) => {
    return response;
  });
}

async function remove(requestParameters) {
  let url = `/runbook/delete?${Utils.objectToQueryString(requestParameters)}`;

  return await http.delete(url).then((response) => {
    return response;
  });
}

async function execute(requestBody) {
  let url = `/runbook/execute`;

  const response = await http.post(url, JSON.stringify(requestBody));
  return response;
}

async function validate(requestBody) {
  let url = `/runbook/validate`;

  const response = await http.post(url, JSON.stringify(requestBody));
  return response;
}
