import { runbookConnectorConstants } from "../constants/runbookConnector.constants";

export function runbookConnector(state = {}, action) {
  switch (action.type) {
    case runbookConnectorConstants.GET_REQUEST:
      return {
        ...state,
        loadingGet: true,
        request: action.payload,
        errorGet: false,
        successGet: false,
      };
    case runbookConnectorConstants.GET_SUCCESS:
      return {
        ...state,
        loadingGet: false,
        paginatedResult: action.payload,
        successGet: true,
      };
    case runbookConnectorConstants.GET_FAILED:
      return {
        ...state,
        loadingGet: false,
        errorGet: true,
        paginatedResult: {},
      };

    case runbookConnectorConstants.CREATE_REQUEST:
      return {
        ...state,
        loadingCreate: true,
        errorCreate: false,
        successCreate: false,
      };
    case runbookConnectorConstants.CREATE_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        successCreate: true,
      };
    case runbookConnectorConstants.CREATE_FAILED:
      return {
        ...state,
        loadingCreate: false,
        errorCreate: true,
      };

    case runbookConnectorConstants.DELETE_REQUEST:
      return {
        ...state,
        loadingDelete: true,
        errorDelete: false,
        successDelete: false,
      };
    case runbookConnectorConstants.DELETE_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case runbookConnectorConstants.DELETE_FAILED:
      return {
        ...state,
        loadingDelete: false,
        errorDelete: true,
      };

    default:
      return state;
  }
}
