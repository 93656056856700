import { http } from "../api/http";
import Utils from "../utils";

export const broadcastMessageReviewService = {
  get,
  create,
};

async function get(requestParameters) {
  let url = `/broadcastmessagereview?${Utils.objectToQueryString(
    requestParameters
  )}`;

  return http.get(url).then((response) => {
    return response;
  });
}

async function create(requestBody) {
  const url = `/broadcastmessagereview/create`;

  try {
    const response = await http.post(url, JSON.stringify(requestBody));
    return response;
  } catch (error) {
    throw error;
  }
}
