import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Utils from "../../utils";
import { clientActions } from "../../actions/client.actions";
import { clientConstants } from "../../constants/client.constants";
import Title from "../common/Title";
import { GoOrganization } from "react-icons/go";
import PlaceHolderLines from "../common/PlaceHolderLines";
import { Alert, Button, Form, Spinner } from "react-bootstrap";

function ClientForm() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [clientId, setClientId] = useState("");
  const [clientGuid, setClientGuid] = useState("");
  const [clientName, setClientName] = useState("");
  const [expiresOn, setExpiresOn] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const clientReducer = useSelector((state) => state.client);

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);
    if (queryParams["clientId"]) {
      setClientId(queryParams["clientId"]);
      dispatch(
        clientActions.get(queryParams, clientConstants.CALLER_CLIENT_GRID)
      );
    } else {
      setClientId("");
      setClientGuid("");
      setClientName("");
      setExpiresOn("");
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (
      clientId &&
      clientReducer.paginatedResultGrid &&
      clientReducer.paginatedResultGrid.data &&
      clientReducer.paginatedResultGrid.data[0]?.clientName
    ) {
      setClientGuid(clientReducer.paginatedResultGrid.data[0].clientGuid);
      setClientName(clientReducer.paginatedResultGrid.data[0].clientName);
      setExpiresOn(
        Utils.formatDateToInput(
          clientReducer.paginatedResultGrid.data[0].expiresOn
        )
      );
    }
    // eslint-disable-next-line
  }, [clientReducer]);

  useEffect(() => {
    if (clientReducer.success) {
      navigate("/client");
    }
    // eslint-disable-next-line
  }, [clientReducer.success]);

  const handleSaveClient = () => {
    setSubmitted(true);

    if (clientName && expiresOn) {
      if (clientId) {
        dispatch(
          clientActions.renew({
            clientId,
            clientGuid,
            expiresOn,
          })
        );
      } else {
        dispatch(
          clientActions.create({
            clientName,
            expiresOn,
          })
        );
      }
    }
  };

  const operation = !clientId ? "Add" : "Renew";

  return (
    <>
      <Title
        text={`${operation} Client`}
        icon={<GoOrganization className="mb-1" />}
      />

      {clientReducer.loadingGrid && <PlaceHolderLines count={4} />}

      {!clientReducer.loadingGrid && (
        <>
          {!clientReducer.success && (
            <>
              {clientReducer.error && (
                <Alert variant={"danger"}>
                  An error has occurred. Please try again later.
                </Alert>
              )}

              {clientId && (
                <Form.Group controlId="formClientId" className="mb-3">
                  <Form.Label>Client Id</Form.Label>
                  <Form.Control
                    type="text"
                    value={clientId}
                    onChange={(e) => setClientId(e.target.value)}
                    disabled
                  />
                </Form.Group>
              )}

              {!clientId && (
                <Form.Group controlId="formClientName" className="mb-3">
                  <Form.Label>Client Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                    className={submitted && !clientName ? "is-invalid" : ""}
                  />
                  <Form.Control.Feedback type="invalid">
                    <small>Please provide a client name.</small>
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              <Form.Group controlId="formExpiresOn" className="mb-3">
                <Form.Label>Expires On</Form.Label>
                <Form.Control
                  type="date"
                  value={expiresOn}
                  onChange={(e) => setExpiresOn(e.target.value)}
                  className={submitted && !expiresOn ? "is-invalid" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  <small>Please provide an expiration date.</small>
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                variant="outline-secondary"
                type="button"
                className="me-3 mt-3"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                type="submit"
                className="mt-3"
                onClick={handleSaveClient}
                disabled={clientReducer.loadingGrid}
              >
                {!clientReducer.loadingGrid ? (
                  <>{operation} Client</>
                ) : (
                  <Spinner size="sm" />
                )}
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
}

export default ClientForm;
