import { Placeholder } from "react-bootstrap";

function PlaceHolderLines(props) {
  return (
    <Placeholder as={"div"} animation="glow">
      {Array(props.count)
        .fill(true)
        .map((_, i) => (
          <Placeholder key={i} xs={12} size="lg" />
        ))}
    </Placeholder>
  );
}

export default PlaceHolderLines;
