import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Form, Button, Alert, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { userActions } from "../../actions/user.actions";
import VerificationInput from "react-verification-input";
import PasswordStrengthBar from "react-password-strength-bar";
import { FaRegCheckCircle } from "react-icons/fa";
import PasswordInput from "../common/PasswordInput";

function SignInHelp() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const signInHelp = useSelector((state) => state.signInHelp);
  const [email, setEmail] = useState("");
  const [submittedFirstStep, setSubmittedFirstStep] = useState(false);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [submittedThirdStep, setSubmittedThirdStep] = useState(false);
  const [passwordConfirmPass, setPasswordConfirmPass] = useState(true);
  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
  const [passwordStrenghtPass, setPasswordStrenghtPass] = useState(true);

  useEffect(() => {
    if (signInHelp.step !== 1) dispatch(userActions.recoverPasswordInit());

    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (code.length === 6) handleValidateCode();

    // eslint-disable-next-line
  }, [code]);

  const handleRecoverPassword = async () => {
    setSubmittedFirstStep(true);

    if (email) dispatch(userActions.recoverPassword(email));
  };

  const handleValidateCode = async () => {
    dispatch(userActions.validateCode(email, code));
  };

  const handleChangePassword = async () => {
    setSubmittedThirdStep(true);
    setPasswordConfirmPass(true);
    setPasswordStrenghtPass(true);

    if (password !== passwordConfirm) setPasswordConfirmPass(false);
    else if (password.length > 0 && passwordStrengthScore < 2)
      setPasswordStrenghtPass(false);
    else {
      setPasswordConfirmPass(true);

      dispatch(userActions.updatePassword(signInHelp.token, password));
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") handleRecoverPassword();
  };

  return (
    <div className="centered-div">
      <Container
        className="login-box bg-white p-5 rounded border border-secondary mt-5"
        onKeyDown={onKeyDown}
      >
        <div className="text-center mb-5">
          <img
            src={require("../../assets/armanino-llp.png")}
            alt="Armanino"
            width={250}
          />
        </div>

        {signInHelp.step === 1 && (
          <>
            {signInHelp.error && (
              <Alert variant={"danger"}>
                An error occurred, please contact Armanino for helping recover
                your password.
              </Alert>
            )}

            <div className="mb-3">
              Please provide the email address you'd like your password reset
              information sent to.
            </div>

            <Form.Group controlId="formBasicEmail" className="mb-3">
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={submittedFirstStep && !email ? "is-invalid" : ""}
              />
              <Form.Control.Feedback type="invalid">
                <small>Please provide your email.</small>
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-grid gap-2 mb-3">
              <Button
                variant="primary"
                type="submit"
                className="mt-3"
                size="lg"
                onClick={handleRecoverPassword}
                disabled={signInHelp.loading}
              >
                {!signInHelp.loading ? (
                  <>Request recovery code</>
                ) : (
                  <Spinner size="sm" />
                )}
              </Button>
            </div>

            <div className="text-center">
              <Link to="/login" className="text-decoration-none">
                Back to Login
              </Link>
            </div>
          </>
        )}

        {signInHelp.step === 2 && (
          <>
            {signInHelp.error && (
              <Alert variant={"danger"}>
                The code you entered is not correct or it is expired. Please try
                again.
              </Alert>
            )}

            <div className="mb-3">
              We've sent you a verification code to your email. Please enter it
              below.
            </div>

            <VerificationInput
              validChars="0-9"
              inputProps={{ inputMode: "numeric" }}
              onChange={(e) => setCode(e)}
              value={code}
              placeholder=""
            />

            <div className="d-grid gap-2 mt-3">
              <Button
                variant="primary"
                type="submit"
                className="mt-3"
                size="lg"
                onClick={handleValidateCode}
                disabled={signInHelp.loading || code.length < 6}
              >
                {!signInHelp.loading ? <>Verify code</> : <Spinner size="sm" />}
              </Button>
            </div>
          </>
        )}

        {signInHelp.step === 3 && (
          <>
            {!passwordConfirmPass && (
              <Alert variant={"danger"}>
                Passwords do not match. Please enter them again below and make
                sure they match.
              </Alert>
            )}

            {!passwordStrenghtPass && (
              <Alert variant={"danger"}>
                Please choose a stronger password. Try mix of letters, numbers,
                and symbols.
              </Alert>
            )}

            <div className="mb-3">Please enter your new password below.</div>

            <Form.Group controlId="formBasicPassword" className="mb-3">
              <PasswordInput
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                additionalCss={submittedThirdStep && !password ? "is-invalid" : ""}
              />
              <Form.Control.Feedback type="invalid">
                <small>Please provide your password.</small>
              </Form.Control.Feedback>

              <PasswordStrengthBar
                password={password}
                onChangeScore={(score) => {
                  setPasswordStrengthScore(score);
                }}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPasswordConfirm" className="mb-3">
              <PasswordInput
                placeholder="Confirm your Password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                additionalCss={submittedThirdStep && !passwordConfirm ? "is-invalid" : ""}
              />
              <Form.Control.Feedback type="invalid">
                <small>Please provide your password confirmation.</small>
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-grid gap-2 mb-3">
              <Button
                variant="primary"
                type="submit"
                className="mt-3"
                size="lg"
                onClick={handleChangePassword}
                disabled={signInHelp.loading}
              >
                {!signInHelp.loading ? (
                  <>Change Password</>
                ) : (
                  <Spinner size="sm" />
                )}
              </Button>
            </div>
          </>
        )}

        {signInHelp.step === 4 && (
          <div className="text-center">
            <FaRegCheckCircle
              className="text-success mb-3"
              size={60}
            ></FaRegCheckCircle>
            <h3 className="mb-3">Password changed!</h3>
            <p>
              You password has been changed succesfully. Please login using your
              new password.
            </p>
            <div className="d-grid gap-2 mb-3">
              <Button
                variant="primary"
                type="submit"
                className="mt-3"
                size="lg"
                onClick={() => {
                  navigate("login");
                }}
                disabled={signInHelp.loading}
              >
                Back to Login
              </Button>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}

export default SignInHelp;
