import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { GiAlarmClock } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { runbookSchedulerActions } from "../../actions/runbookScheduler.actions";
import Grid from "../common/Grid";
import Utils from "../../utils";

function NextExecutions(props) {
  const dispatch = useDispatch();
  const dashboardReducer = useSelector((state) => state.dashboard);

  useEffect(() => {
    let queryParams = {
      clientId: props.user.clientId,
      clientGuid: props.user.clientGuid,
    };

    dispatch(runbookSchedulerActions.getNextExecutions(queryParams));

    // eslint-disable-next-line
  }, [location]);

  const data = dashboardReducer.paginatedNextExecutionsResult?.data.map((r) => {
    return {
      ...r,
      executionDate: Utils.convertToDateTime(r.executionDate),
    };
  });

  return (
    <Card className="shadow-lg">
      <Card.Header className="fw-bold fs-6">
        <GiAlarmClock className="mb-1 me-2" />
        Next Executions
      </Card.Header>
      <Card.Body>
        <Card.Text as="div">
          <Grid
            data={data}
            displayColumns={["runbookId", "runbookName", "executionDate"]}
            pageNumber={0}
            totalPages={data?.length ? 1 : 0}
            loading={dashboardReducer.loadingNextExecutions}
            placeHolderLinesCount={6}
          ></Grid>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default NextExecutions;
