export const broadcastMessageReviewConstants = {
  CREATE_BROADCAST_MESSAGE_REVIEW_REQUEST:
    "CREATE_BROADCAST_MESSAGE_REVIEW_REQUEST",
  CREATE_BROADCAST_MESSAGE_REVIEW_SUCCESS:
    "CREATE_BROADCAST_MESSAGE_REVIEW_SUCCESS",
  CREATE_BROADCAST_MESSAGE_REVIEW_FAILURE:
    "CREATE_BROADCAST_MESSAGE_REVIEW_FAILURE",
  GET_BROADCAST_MESSAGE_REVIEW_REQUEST: "GET_BROADCAST_MESSAGE_REVIEW_REQUEST",
  GET_BROADCAST_MESSAGE_REVIEW_SUCCESS: "GET_BROADCAST_MESSAGE_REVIEW_SUCCESS",
  GET_BROADCAST_MESSAGE_REVIEW_FAILURE: "GET_BROADCAST_MESSAGE_REVIEW_FAILURE",
};
