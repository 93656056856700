import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { CiClock1 } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { runbookExecutionHistoryActions } from "../../actions/runbookExecutionHistory.actions";
import Grid from "../common/Grid";
import Utils from "../../utils";
import StatusHelper from "../helpers/statusHelper";

function LatestExecutions(props) {
  const dispatch = useDispatch();
  const dashboardReducer = useSelector((state) => state.dashboard);

  useEffect(() => {
    let queryParams = {
      clientId: props.user.clientId,
      clientGuid: props.user.clientGuid,
    };

    dispatch(runbookExecutionHistoryActions.getLatestExecutions(queryParams));

    // eslint-disable-next-line
  }, [location]);

  const data = dashboardReducer.paginatedLatestExecutionsResult?.data.map(
    (r) => {
      return {
        ...r,
        executionDate: Utils.convertToDateTime(r.executionDate),
        status: StatusHelper.getBadge(r.status),
      };
    }
  );

  return (
    <Card className="shadow-lg">
      <Card.Header className="fw-bold fs-6">
        <CiClock1 className="mb-1 me-2" />
        Latest Executions
      </Card.Header>
      <Card.Body>
        <Card.Text as="div">
          <Grid
            data={data}
            displayColumns={[
              "runbookId",
              "runbookName",
              "executionDate",
              "status",
            ]}
            pageNumber={0}
            totalPages={data?.length ? 1 : 0}
            loading={dashboardReducer.loadingLatestExecutions}
            placeHolderLinesCount={6}
          ></Grid>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default LatestExecutions;
