import { settingsService } from "../services/settings.service";
import { settingsConstants } from "../constants/settings.constants";

export const settingsActions = {
  get,
};

function get() {
  return (dispatch) => {
    dispatch(request());

    settingsService.get().then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request() {
    return { type: settingsConstants.GET_REQUEST };
  }
  function success(result) {
    return { type: settingsConstants.GET_SUCCESS, payload: result };
  }
  function failure() {
    return { type: settingsConstants.GET_FAILURE };
  }
}
