import React, { useState, useEffect } from "react";
import { Form, ListGroup } from "react-bootstrap";

const Autocomplete = ({ suggestions, onSelect, onTextChange, placeholder }) => {
  const [query, setQuery] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [blurTimeout, setBlurTimeout] = useState(null);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setShowSuggestions(value.length > 0);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    setDebounceTimeout(
      setTimeout(() => {
        onTextChange(value);
      }, 400)
    );
  };

  const handleBlur = () => {
    setBlurTimeout(
      setTimeout(() => {
        setShowSuggestions(false);
      }, 150)
    );
  };

  const handleFocus = () => {
    setShowSuggestions(true);
  };

  const handleMouseDown = () => {
    if (blurTimeout) {
      clearTimeout(blurTimeout);
    }
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
      if (blurTimeout) {
        clearTimeout(blurTimeout);
      }
    };
  }, [debounceTimeout, blurTimeout]);

  return (
    <div style={{ position: "relative" }}>
      <Form.Control
        type="text"
        placeholder={placeholder}
        value={query}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      {showSuggestions && suggestions && suggestions.length > 0 && (
        <ListGroup style={{ position: "absolute", zIndex: 10, width: "100%" }}>
          {suggestions.map((suggestion) => (
            <ListGroup.Item
              key={suggestion.id}
              onMouseDown={handleMouseDown}
              onClick={() => {
                onSelect(suggestion.id);
                setQuery("");
                setShowSuggestions(false);
              }}
              style={{ cursor: "pointer" }}
            >
              {suggestion.name}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default Autocomplete;
