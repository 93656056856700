import { settingsConstants } from "../constants/settings.constants";

export function settings(state = {}, action) {
  switch (action.type) {
    case settingsConstants.GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case settingsConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload,
      };
    case settingsConstants.GET_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
