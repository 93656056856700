export const invitationConstants = {
  OPEN_MODAL: "OPEN_MODAL",
  SEND_REQUEST: "SEND_REQUEST",
  SEND_SUCCESS: "SEND_SUCCESS",
  SEND_FAILURE: "SEND_FAILURE",
  CLOSE_MODAL: "CLOSE_MODAL",
  GET_BY_SENDER_ID_REQUEST: "GET_BY_SENDER_ID_REQUEST",
  GET_BY_SENDER_ID_SUCCESS: "GET_BY_SENDER_ID_SUCCESS",
  GET_BY_SENDER_ID_FAILURE: "GET_BY_SENDER_ID_FAILURE",
};
