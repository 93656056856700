import React, { useEffect, useState } from "react";
import Title from "../common/Title";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import Utils from "../../utils";
import { runbookActions } from "../../actions/runbook.actions";
import { runbookServerActions } from "../../actions/runbookServer.actions";
import PlaceHolderLines from "../common/PlaceHolderLines";
import { CiBoxList } from "react-icons/ci";

function RunbookForm(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [runbookId, setRunbookId] = useState("");
  const [runbookName, setRunbookName] = useState("");
  const [description, setDescription] = useState("");
  const [isTemplate, setIsTemplate] = useState(false);
  const [activeVersion, setActiveVersion] = useState(1);
  const [runbookServer, setRunbookServer] = useState("");
  const [debugMode, setDebugMode] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const runbookReducer = useSelector((state) => state.runbook);
  const runbookServerReducer = useSelector((state) => state.runbookServer);

  useEffect(() => {
    if (!runbookServerReducer.response) dispatch(runbookServerActions.getAll());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const queryParams = Utils.getQueryStringParams(location);
    if (queryParams["runbookId"]) {
      queryParams["clientId"] = props.user.clientId;
      queryParams["clientGuid"] = props.user.clientGuid;
      setRunbookId(queryParams["runbookId"]);
      dispatch(runbookActions.get(queryParams));
    } else {
      setRunbookId("");
      setRunbookName("");
      setDescription("");
      setIsTemplate(false);
      setActiveVersion(1);
      setRunbookServer("");
      setDebugMode(false);
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (
      runbookId &&
      runbookReducer.paginatedResult.data[0] &&
      Object.keys(runbookReducer.paginatedResult.data[0]).length > 0
    ) {
      const {
        runbookName,
        description,
        isTemplate,
        activeVersion,
        runbookServer,
        debugMode,
      } = runbookReducer.paginatedResult.data[0];

      setRunbookName(runbookName || "");
      setDescription(description || "");
      setIsTemplate(!!isTemplate);
      setActiveVersion(activeVersion || 0);
      setRunbookServer(runbookServer || "");
      setDebugMode(!!debugMode);
    }
    // eslint-disable-next-line
  }, [runbookReducer.paginatedResult]);

  useEffect(() => {
    if (runbookReducer.success) {
      navigate("/runbook");
    }
    // eslint-disable-next-line
  }, [runbookReducer.success]);

  const handleSaveRunbook = () => {
    setSubmitted(true);

    if (runbookName && description && runbookServer) {
      const payload = {
        runbookName,
        description,
        isTemplate,
        activeVersion,
        runbookServer,
        debugMode,
        clientId: props.user.clientId,
        clientGuid: props.user.clientGuid,
      };

      if (runbookId) {
        dispatch(runbookActions.update({ ...payload, runbookId }));
      } else {
        dispatch(runbookActions.create(payload));
      }
    }
  };

  const operation = !runbookId ? "Add" : "Update";
  const loading = runbookReducer.loading || runbookServerReducer.loading;

  return (
    <>
      <Title
        text={`${operation} Runbook`}
        icon={<CiBoxList className="mb-1" />}
      />

      {loading && <PlaceHolderLines count={4} />}

      {!loading && (
        <>
          {!runbookReducer.success && (
            <>
              {runbookReducer.error && (
                <Alert variant={"danger"}>
                  An error has occurred. Please try again later.
                </Alert>
              )}

              <Form.Group controlId="formRunbookName" className="mb-3">
                <Form.Label>Runbook Name</Form.Label>
                <Form.Control
                  type="text"
                  value={runbookName}
                  onChange={(e) => setRunbookName(e.target.value)}
                  className={submitted && !runbookName ? "is-invalid" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  <small>Please provide the runbook name.</small>
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formDescription" className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className={submitted && !description ? "is-invalid" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  <small>Please provide the description.</small>
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formRunbookServer" className="mb-3">
                <Form.Label>Runbook Server</Form.Label>
                <Form.Select
                  value={runbookServer}
                  onChange={(e) => setRunbookServer(e.target.value)}
                  className={submitted && !runbookServer ? "is-invalid" : ""}
                >
                  <option key="" value="">
                    Select a server
                  </option>
                  {runbookServerReducer.response?.map((server) => (
                    <option key={server.serverName} value={server.serverName}>
                      {server.serverName}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  <small>Please select a runbook server.</small>
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formIsTemplate" className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Is Template"
                  checked={isTemplate}
                  onChange={(e) => setIsTemplate(e.target.checked)}
                />
              </Form.Group>

              <Form.Group controlId="formActiveVersion" className="mb-3">
                <Form.Label>Active Version</Form.Label>
                <Form.Control
                  type="number"
                  value={activeVersion}
                  onChange={(e) => setActiveVersion(Number(e.target.value))}
                />
              </Form.Group>

              <Form.Group controlId="formDebugMode" className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Debug Mode"
                  checked={debugMode}
                  onChange={(e) => setDebugMode(e.target.checked)}
                />
              </Form.Group>

              <Button
                variant="outline-secondary"
                type="button"
                className="me-3 mt-3"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                type="submit"
                className="mt-3"
                onClick={handleSaveRunbook}
                disabled={runbookReducer.loading}
              >
                {!runbookReducer.loading ? (
                  <>{operation} Runbook</>
                ) : (
                  <Spinner size="sm" />
                )}
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
}

export default RunbookForm;
