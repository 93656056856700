export const clientVariableConstants = {
  GET_REQUEST: "GET_CLIENTVARIABLE_REQUEST",
  GET_SUCCESS: "GET_CLIENTVARIABLE_SUCCESS",
  GET_FAILURE: "GET_CLIENTVARIABLE_FAILURE",
  UPDATE_REQUEST: "UPDATE_CLIENTVARIABLE_REQUEST",
  UPDATE_SUCCESS: "UPDATE_CLIENTVARIABLE_SUCCESS",
  UPDATE_FAILURE: "UPDATE_CLIENTVARIABLE_FAILURE",
  CREATE_REQUEST: "CREATE_CLIENTVARIABLE_REQUEST",
  CREATE_SUCCESS: "CREATE_CLIENTVARIABLE_SUCCESS",
  CREATE_FAILURE: "CREATE_CLIENTVARIABLE_FAILURE",
};
