import { clientService } from "../services/client.service";
import { clientConstants } from "../constants/client.constants";

export const clientActions = {
  get,
  create,
  renew,
};

function get(requestParameters, caller) {
  return (dispatch) => {
    dispatch(request(requestParameters, caller));

    clientService.get(requestParameters).then(
      (response) => {
        dispatch(success(response.data, caller));
      },
      (error) => {
        dispatch(failure(caller));
      }
    );
  };

  function request(requestParameters, caller) {
    return {
      type:
        caller === clientConstants.CALLER_CLIENT_MENU
          ? clientConstants.GET_CLIENT_MENU_REQUEST
          : clientConstants.GET_CLIENT_GRID_REQUEST,
      payload: requestParameters,
    };
  }
  function success(paginatedResult, caller) {
    return {
      type:
        caller === clientConstants.CALLER_CLIENT_MENU
          ? clientConstants.GET_CLIENT_MENU_SUCCESS
          : clientConstants.GET_CLIENT_GRID_SUCCESS,
      payload: paginatedResult,
    };
  }
  function failure(caller) {
    return {
      type:
        caller === clientConstants.CALLER_CLIENT_MENU
          ? clientConstants.GET_CLIENT_MENU_FAILURE
          : clientConstants.GET_CLIENT_GRID_FAILURE,
    };
  }
}

function create(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    clientService.create(requestParameters).then(
      (response) => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: clientConstants.CREATE_REQUEST,
      payload: requestParameters,
    };
  }
  function success() {
    return { type: clientConstants.CREATE_SUCCESS };
  }
  function failure() {
    return { type: clientConstants.CREATE_FAILURE };
  }
}

function renew(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    clientService.renew(requestParameters).then(
      (response) => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: clientConstants.RENEW_REQUEST,
      payload: requestParameters,
    };
  }
  function success() {
    return { type: clientConstants.RENEW_SUCCESS };
  }
  function failure() {
    return { type: clientConstants.RENEW_FAILURE };
  }
}
