export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  LOGOUT_REQUEST: "USERS_LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "USERS_LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "USERS_LOGOUT_FAILURE",
  RECOVER_PASSWORD_INIT: "RECOVER_PASSWORD_INIT",
  RECOVER_PASSWORD_REQUEST: "RECOVER_PASSWORD_REQUEST",
  RECOVER_PASSWORD_SUCCESS: "RECOVER_PASSWORD_SUCCESS",
  RECOVER_PASSWORD_FAILURE: "RECOVER_PASSWORD_FAILURE",
  VERIFY_CODE_REQUEST: "VERIFY_CODE_REQUEST",
  VERIFY_CODE_SUCCESS: "VERIFY_CODE_SUCCESS",
  VERIFY_CODE_FAILURE: "VERIFY_CODE_FAILURE",
  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  CREATE_REQUEST: "CREATE_REQUEST",
  CREATE_SUCCESS: "CREATE_SUCCESS",
  CREATE_FAILURE: "CREATE_FAILURE",
  GET_REQUEST: "GET_USER_REQUEST",
  GET_SUCCESS: "GET_USER_SUCCESS",
  GET_FAILURE: "GET_USER_FAILURE",
  GET_BY_ID_REQUEST: "GET_USER_BY_ID_REQUEST",
  GET_BY_ID_SUCCESS: "GET_USER_BY_ID_SUCCESS",
  GET_BY_ID_FAILURE: "GET_USER_BY_ID_FAILURE",
  UPDATE_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_FAILURE: "UPDATE_USER_FAILURE",
  DELETE_REQUEST: "DELETE_USER_REQUEST",
  DELETE_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_FAILURE: "DELETE_USER_FAILURE",
};
