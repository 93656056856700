import { userConstants } from "../constants/user.constants";

export function signInHelp(state = {}, action) {
  switch (action.type) {
    case userConstants.RECOVER_PASSWORD_INIT:
      return {
        ...state,
        step: 1,
        error: false,
        token: "",
        passwordChanged: false,
      };
    case userConstants.RECOVER_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        step: 2,
      };
    case userConstants.CHANGE_PASSWORD_REQUEST:
    case userConstants.VERIFY_CODE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        request: action.payload,
      };
    case userConstants.VERIFY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        step: 3,
        token: action.payload,
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        passwordChanged: true,
        step: 4,
      };
    case userConstants.RECOVER_PASSWORD_FAILURE:
    case userConstants.VERIFY_CODE_FAILURE:
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
