import { clientVariableLineService } from "../services/clientVariableLine.service";
import { clientVariableLineConstants } from "../constants/clientVariableLine.constants";

export const clientVariableLineActions = {
  get,
  create,
  remove,
  update,
};

function get(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    clientVariableLineService.get(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: clientVariableLineConstants.GET_REQUEST,
      payload: requestParameters,
    };
  }
  function success(paginatedResult) {
    return {
      type: clientVariableLineConstants.GET_SUCCESS,
      payload: paginatedResult,
    };
  }
  function failure() {
    return { type: clientVariableLineConstants.GET_FAILURE };
  }
}

function create(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    clientVariableLineService.create(requestParameters).then(
      (response) => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: clientVariableLineConstants.CREATE_REQUEST,
      payload: requestParameters,
    };
  }
  function success() {
    return { type: clientVariableLineConstants.CREATE_SUCCESS };
  }
  function failure() {
    return { type: clientVariableLineConstants.CREATE_FAILURE };
  }
}

function remove(requestParameters) {
  return (dispatch) => {
    dispatch(request());

    clientVariableLineService.remove(requestParameters).then(
      (response) => {
        window.location.reload();
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request() {
    return {
      type: clientVariableLineConstants.DELETE_REQUEST,
    };
  }
  function failure() {
    return { type: clientVariableLineConstants.DELETE_FAILURE };
  }
}

function update(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    clientVariableLineService.update(requestParameters).then(
      (response) => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: clientVariableLineConstants.UPDATE_REQUEST,
      payload: requestParameters,
    };
  }
  function success() {
    return { type: clientVariableLineConstants.UPDATE_SUCCESS };
  }
  function failure() {
    return { type: clientVariableLineConstants.UPDATE_FAILURE };
  }
}
