import { http } from "../api/http";

export const runbookServerService = {
  getAll,
};

function getAll() {
  let url = "/runbookserver/getall";

  return http.get(url).then((response) => {
    return response;
  });
}
