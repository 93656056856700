import { runbookStepConstants } from "../constants/runbookStep.constants";

export function runbookStep(state = {}, action) {
  switch (action.type) {
    case runbookStepConstants.GET_REQUEST:
      return {
        ...state,
        loading: true,
        request: action.payload,
      };
    case runbookStepConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case runbookStepConstants.GET_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
