import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

function PasswordInput({ value, additionalCss, placeholder, onChange }) {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className={
        "password-input-container" + (additionalCss ? " " + additionalCss : "")
      }
    >
      <Form.Control
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        autoComplete="new-password"
        className={
          "password-input" + (additionalCss ? " " + additionalCss : "")
        }
      />
      <button
        type="button"
        onClick={toggleShowPassword}
        className="toggle-button"
        aria-label={showPassword ? "Hide password" : "Show password"}
      >
        {!additionalCss && (
          <>{showPassword ? <FaRegEyeSlash /> : <FaRegEye />}</>
        )}
      </button>
    </div>
  );
}

export default PasswordInput;
