export const clientVariableLineConstants = {
  GET_REQUEST: "GET_CLIENTVARIABLELINE_REQUEST",
  GET_SUCCESS: "GET_CLIENTVARIABLELINE_SUCCESS",
  GET_FAILURE: "GET_CLIENTVARIABLELINE_FAILURE",
  CREATE_REQUEST: "CREATE_CLIENTVARIABLELINE_REQUEST",
  CREATE_SUCCESS: "CREATE_CLIENTVARIABLELINE_SUCCESS",
  CREATE_FAILURE: "CREATE_CLIENTVARIABLELINE_FAILURE",
  UPDATE_REQUEST: "UPDATE_CLIENTVARIABLELINE_REQUEST",
  UPDATE_SUCCESS: "UPDATE_CLIENTVARIABLELINE_SUCCESS",
  UPDATE_FAILURE: "UPDATE_CLIENTVARIABLELINE_FAILURE",
  DELETE_REQUEST: "DELETE_CLIENTVARIABLELINE_REQUEST",
  DELETE_SUCCESS: "DELETE_CLIENTVARIABLELINE_SUCCESS",
  DELETE_FAILURE: "DELETE_CLIENTVARIABLELINE_FAILURE",
};
