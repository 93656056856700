import { http } from "../api/http";

export const settingsService = {
  get,
};

function get() {
  let url = `/settings`;

  return http.get(url).then((response) => {
    return response;
  });
}
