import React from "react";
import { NavLink } from "react-router-dom";
import { MdInsertInvitation, MdMenuBook } from "react-icons/md";
import { RiDashboard3Line } from "react-icons/ri";
import { CiBoxList } from "react-icons/ci";
import { GiAlarmClock } from "react-icons/gi";
import { LuVariable } from "react-icons/lu";
import { FaDatabase } from "react-icons/fa";
import { GoOrganization } from "react-icons/go";
import { GiMegaphone } from "react-icons/gi";
import { AiOutlineSetting } from "react-icons/ai";
import { FaPerson } from "react-icons/fa6";

function LeftMenu({ user }) {
  return (
    <div
      className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark bg-gradient"
      style={{ width: "200px" }}
    >
      <span className="fs-5">
        <MdMenuBook className="mb-2 me-3" />
        Menu
      </span>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item">
          <NavLink to="/dashboard" className="nav-link">
            <RiDashboard3Line className="mb-1" /> Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink to="/runbook" className="nav-link">
            <CiBoxList className="mb-1" /> My Runbooks
          </NavLink>
        </li>
        <li>
          <NavLink to="/scheduler" className="nav-link">
            <GiAlarmClock className="mb-1" /> My Schedulers
          </NavLink>
        </li>
        <li>
          <NavLink to="/variable" className="nav-link">
            <LuVariable className="mb-1" /> My Variables
          </NavLink>
        </li>
        <li>
          <NavLink to="/connector" className="nav-link">
            <FaDatabase className="mb-1" /> My Connectors
          </NavLink>
        </li>
        {(user?.isSuperUser || user?.isAdmin) && (
          <>
            <hr />
            <span className="fs-6 mb-2">
              <AiOutlineSetting className="mb-1 me-2" />
              Settings
            </span>
          </>
        )}
        {user?.isSuperUser && (
          <>
            <li>
              <NavLink to="/user" className="nav-link">
                <FaPerson className="mb-1" /> Users
              </NavLink>
            </li>
            <li>
              <NavLink to="/client" className="nav-link">
                <GoOrganization className="mb-1" /> Clients
              </NavLink>
            </li>
            <li>
              <NavLink to="/messages" className="nav-link">
                <GiMegaphone className="mb-1" /> Messages
              </NavLink>
            </li>
          </>
        )}
        {user?.isAdmin && (
          <>
            <NavLink to="/invitation" className="nav-link">
              <MdInsertInvitation className="mb-1" /> Invitations
            </NavLink>
          </>
        )}
      </ul>
    </div>
  );
}

export default LeftMenu;
