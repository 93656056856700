import { http } from "../api/http";
import Utils from "../utils";

export const invitationService = {
  get,
  getBySender,
  invite,
};

function get(id) {
  let url = `/invitation?invitation_id=${id}`;

  return http.get(url).then((response) => {
    return response;
  });
}

function getBySender(requestParameters) {
  let url = `/invitation/sender?${Utils.objectToQueryString(
    requestParameters
  )}`;

  return http.get(url).then((response) => {
    return response;
  });
}

function invite(
  fromFirstName,
  fromLastName,
  email,
  clientId,
  clientGuid,
  isAdmin
) {
  let url = `/invitation/invite`;

  return http
    .post(
      url,
      JSON.stringify({
        fromFirstName,
        fromLastName,
        email,
        clientId,
        clientGuid,
        isAdmin,
      })
    )
    .then((response) => {
      return response;
    });
}
