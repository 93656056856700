import React, { useEffect } from "react";
import { Row, Col, Card, Placeholder } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { MdError, MdCategory } from "react-icons/md";
import { CiTimer } from "react-icons/ci";
import { GrTrigger } from "react-icons/gr";
import GaugeKPI from "./GaugeKPI";
import Pie from "./Pie";
import { dashboardActions } from "../../actions/dashboard.actions";
import TimeDisplay from "./TimeDisplay";

function KPIs(props) {
  const dispatch = useDispatch();
  const dashboardReducer = useSelector((state) => state.dashboard);

  useEffect(() => {
    let queryParams = {
      clientId: props.user.clientId,
      clientGuid: props.user.clientGuid,
    };

    dispatch(dashboardActions.get(queryParams));

    // eslint-disable-next-line
  }, [location]);

  const categoriesPieData = dashboardReducer.kpisResult
    ? [
        {
          title: "On Schedule",
          value: dashboardReducer.kpisResult.runbookCategoriesOnSchedule,
        },
        {
          title: "Manual",
          value: dashboardReducer.kpisResult.runbookCategoriesManual,
        },
      ]
    : [];

  const triggersPieData = dashboardReducer.kpisResult
    ? [
        {
          title: "Pushed Data",
          value: dashboardReducer.kpisResult.executionTriggerPushedData,
        },
        {
          title: "Manual",
          value: dashboardReducer.kpisResult.executionTriggerManual,
        },
        {
          title: "Scheduled",
          value: dashboardReducer.kpisResult.executionTriggerScheduled,
        },
      ]
    : [];

  return (
    <>
      <Row className="mb-3">
        <Col md={12} lg={3}>
          <Card className="h-100 shadow-lg">
            <Card.Header className="fw-bold fs-6">
              <MdError className="mb-1 me-2" />
              Successful Executions
            </Card.Header>
            <Card.Body as={"div"}>
              <Card.Text as={"div"} className="h-100">
                <GaugeKPI
                  monthPercent={
                    (dashboardReducer.kpisResult?.successfulExecutionMonth ??
                      0) / 100
                  }
                  yearPercent={
                    dashboardReducer.kpisResult?.successfulExecutionYear
                  }
                  allTimePercent={
                    dashboardReducer.kpisResult?.successfulExecutionAllTime
                  }
                  loading={dashboardReducer.loadingKpis}
                />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={12} lg={3}>
          <Card className="h-100 shadow-lg">
            <Card.Header className="fw-bold fs-6">
              <MdError className="mb-1 me-2" />
              Successful Runbooks
            </Card.Header>
            <Card.Body as={"div"}>
              <Card.Text as={"div"} className="h-100">
                <GaugeKPI
                  monthPercent={
                    (dashboardReducer.kpisResult?.successfulRunbookMonth ?? 0) /
                    100
                  }
                  yearPercent={
                    dashboardReducer.kpisResult?.successfulRunbookYear
                  }
                  allTimePercent={
                    dashboardReducer.kpisResult?.successfulRunbookAllTime
                  }
                  loading={dashboardReducer.loadingKpis}
                />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={12} lg={3}>
          <Card className="h-100 shadow-lg">
            <Card.Header className="fw-bold fs-6">
              <MdCategory className="mb-1 me-2" />
              Runbook Categories
            </Card.Header>
            <Card.Body as={"div"}>
              <Card.Text as={"div"}>
                <Pie
                  data={categoriesPieData}
                  loading={dashboardReducer.loadingKpis}
                />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={12} lg={3}>
          <Card className="h-100 shadow-lg">
            <Card.Header className="fw-bold fs-6">
              <GrTrigger className="mb-1 me-2" />
              Execution Triggers
            </Card.Header>
            <Card.Body as={"div"}>
              <Card.Text as={"div"}>
                <Pie
                  data={triggersPieData}
                  loading={dashboardReducer.loadingKpis}
                />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Card className="h-100 shadow-lg">
            <Card.Header className="fw-bold fs-6">
              <CiTimer className="mb-1 me-2" />
              Average Execution Time Last Month
            </Card.Header>
            <Card.Body className="text-center fs-2 fw-bold">
              <Card.Text as={"div"} className="h-100">
                {!dashboardReducer.loadingKpis && (
                  <TimeDisplay
                    time={dashboardReducer.kpisResult?.avgExecutionTime}
                  />
                )}
                {dashboardReducer.loadingKpis && (
                  <Placeholder
                    as={"div"}
                    style={{ height: "100%" }}
                    animation="glow"
                  >
                    <Placeholder xs={12} size="md" style={{ height: "100%" }} />
                  </Placeholder>
                )}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default KPIs;
