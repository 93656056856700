import Gauge from "./Gauge";
import TextKpi from "./TextKpi";
import { Placeholder } from "react-bootstrap";

function GaugeKPI(props) {
  return (
    <>
      {!props.loading && (
        <>
          <Gauge percent={props.monthPercent ?? 0} />
          <small>During last 30 days</small>
          <hr />
          <TextKpi
            text={"During last year:"}
            percent={(props.yearPercent ?? 0).toFixed(2)}
          />
          <TextKpi
            text={"All time:"}
            percent={(props.allTimePercent ?? 0).toFixed(2)}
          />
        </>
      )}
      {props.loading && (
        <Placeholder as={"div"} style={{ height: "100%" }} animation="glow">
          <Placeholder xs={12} size="lg" style={{ height: "100%" }} />
        </Placeholder>
      )}
    </>
  );
}

export default GaugeKPI;
