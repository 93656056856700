export const broadcastMessageConstants = {
  GET_BROADCAST_MESSAGES_REQUEST: "GET_BROADCAST_MESSAGES_REQUEST",
  GET_BROADCAST_MESSAGES_SUCCESS: "GET_BROADCAST_MESSAGES_SUCCESS",
  GET_BROADCAST_MESSAGES_FAILURE: "GET_BROADCAST_MESSAGES_FAILURE",

  CREATE_BROADCAST_MESSAGE_REQUEST: "CREATE_BROADCAST_MESSAGE_REQUEST",
  CREATE_BROADCAST_MESSAGE_SUCCESS: "CREATE_BROADCAST_MESSAGE_SUCCESS",
  CREATE_BROADCAST_MESSAGE_FAILURE: "CREATE_BROADCAST_MESSAGE_FAILURE",

  UPDATE_BROADCAST_MESSAGE_REQUEST: "UPDATE_BROADCAST_MESSAGE_REQUEST",
  UPDATE_BROADCAST_MESSAGE_SUCCESS: "UPDATE_BROADCAST_MESSAGE_SUCCESS",
  UPDATE_BROADCAST_MESSAGE_FAILURE: "UPDATE_BROADCAST_MESSAGE_FAILURE",

  DELETE_BROADCAST_MESSAGE_REQUEST: "DELETE_BROADCAST_MESSAGE_REQUEST",
  DELETE_BROADCAST_MESSAGE_SUCCESS: "DELETE_BROADCAST_MESSAGE_SUCCESS",
  DELETE_BROADCAST_MESSAGE_FAILURE: "DELETE_BROADCAST_MESSAGE_FAILURE",

  GET_BROADCAST_MESSAGE_BY_ID_REQUEST: "GET_BROADCAST_MESSAGE_BY_ID_REQUEST",
  GET_BROADCAST_MESSAGE_BY_ID_SUCCESS: "GET_BROADCAST_MESSAGE_BY_ID_SUCCESS",
  GET_BROADCAST_MESSAGE_BY_ID_FAILURE: "GET_BROADCAST_MESSAGE_BY_ID_FAILURE",

  GET_BROADCAST_MESSAGES_FOR_USER_REQUEST:
    "GET_BROADCAST_MESSAGES_FOR_USER_REQUEST",
  GET_BROADCAST_MESSAGES_FOR_USER_SUCCESS:
    "GET_BROADCAST_MESSAGES_FOR_USER_SUCCESS",
  GET_BROADCAST_MESSAGES_FOR_USER_FAILURE:
    "GET_BROADCAST_MESSAGES_FOR_USER_FAILURE",
};
