import React from "react";
import { CiCloudOff } from "react-icons/ci";

function NoResults(props) {
  return (
    <div className="centered-div">
      <div className={`text-center ${props.noMarging ? "" : "my-5"}`}>
        <CiCloudOff size={180} className="text-muted" />
        <h2 className="fw-bold">No data to display</h2>
        Please try later
      </div>
    </div>
  );
}

export default NoResults;
