import { clientConstants } from "../constants/client.constants";

export function client(state = {}, action) {
  switch (action.type) {
    case clientConstants.GET_CLIENT_MENU_REQUEST:
      return {
        ...state,
        loadingClientMenu: true,
        requestClientMenu: action.payload,
      };
    case clientConstants.GET_CLIENT_GRID_REQUEST:
    case clientConstants.CREATE_REQUEST:
    case clientConstants.RENEW_REQUEST:
      return {
        ...state,
        loadingGrid: true,
        requestGrid: action.payload,
        error: false,
        success: false,
      };
    case clientConstants.GET_CLIENT_MENU_SUCCESS:
      return {
        ...state,
        loadingClientMenu: false,
        paginatedResultClientMenu: action.payload,
      };
    case clientConstants.GET_CLIENT_GRID_SUCCESS:
      return {
        ...state,
        loadingGrid: false,
        paginatedResultGrid: action.payload,
      };
    case clientConstants.GET_CLIENT_MENU_FAILURE:
      return {
        ...state,
        loadingClientMenu: false,
      };
    case clientConstants.GET_CLIENT_GRID_FAILURE:
    case clientConstants.CREATE_FAILURE:
    case clientConstants.RENEW_FAILURE:
      return {
        ...state,
        loadingGrid: false,
        error: true,
      };
    case clientConstants.CREATE_SUCCESS:
    case clientConstants.RENEW_SUCCESS:
      return {
        ...state,
        loadingGrid: false,
        success: true,
      };
    default:
      return state;
  }
}
