import React, { useEffect, useState } from "react";
import { Dropdown, Form, Spinner } from "react-bootstrap";
import { GoOrganization } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { clientActions } from "../../actions/client.actions";
import { userActions } from "../../actions/user.actions";
import { clientConstants } from "../../constants/client.constants";

function ClientMenu({ user }) {
  const dispatch = useDispatch();
  const clientReducer = useSelector((state) => state.client);
  const [searchText, setSearchText] = useState("");

  let clientName = user.clientName;
  if (!user.clientName) {
    if (user.isSuperUser) clientName = "All Clients (Superuser)";
    else clientName = "-";
  }

  const defaultParams = {
    ClientName: "",
    current_page: 0,
    max_results: 10,
    order_by: "ClientName",
  };

  useEffect(() => {
    if (user.isSuperUser) {
      let params = defaultParams;
      dispatch(clientActions.get(params, clientConstants.CALLER_CLIENT_MENU));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user.isSuperUser) {
      const delayDebounceFn = setTimeout(() => {
        let params = defaultParams;
        params.ClientName = searchText;
        dispatch(clientActions.get(params, clientConstants.CALLER_CLIENT_MENU));
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line
  }, [searchText]);

  const handleClientClick = (clientId, clientGuid, clientName) => {
    userActions.changeClient(clientId, clientGuid, clientName);
  };

  return (
    <div className="client-menu me-3">
      <Dropdown align={"end"}>
        <Dropdown.Toggle
          variant="link"
          className="text-decoration-none text-body"
          disabled={!user.isSuperUser}
        >
          <small>
            <small className="fw-bold">
              <GoOrganization className="me-2 mb-1" />
              {clientName}
            </small>
          </small>
        </Dropdown.Toggle>
        <Dropdown.Menu className="rounded-0 shadow-lg p-3">
          <Dropdown.ItemText>
            <Form.Group className="w-100">
              <Form.Label className="fw-bold text-primary mb-3">
                Search Client
              </Form.Label>
              <Form.Control
                type="text"
                className="bg-transparent"
                placeholder="Enter client name"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Form.Group>
          </Dropdown.ItemText>
          <hr className="mb-0" />
          {!clientReducer.loadingClientMenu && (
            <>
              <Dropdown.Item
                className="py-3"
                onClick={() => handleClientClick("", "", "")}
              >
                <GoOrganization className="me-2 mb-1" />
                All Clients (Superuser)
              </Dropdown.Item>
              {clientReducer.paginatedResultClientMenu?.data.map((c) => {
                return (
                  <Dropdown.Item
                    key={c.clientId}
                    className="py-3"
                    onClick={() =>
                      handleClientClick(c.clientId, c.clientGuid, c.clientName)
                    }
                  >
                    <GoOrganization className="me-2 mb-1" />
                    {c.clientName}
                  </Dropdown.Item>
                );
              })}
              <Dropdown.ItemText className="text-center fw-bold text-muted">
                <small>
                  There
                  {clientReducer.paginatedResultClientMenu?.totalItems === 1
                    ? " is "
                    : " are "}
                  {clientReducer.paginatedResultClientMenu?.totalItems} client
                  {clientReducer.paginatedResultClientMenu?.totalItems === 1
                    ? ""
                    : "s"}
                  {clientReducer.request?.ClientName
                    ? ` matching '${clientReducer.request?.ClientName}'`
                    : ""}
                </small>
              </Dropdown.ItemText>
            </>
          )}
          {clientReducer.loadingClientMenu && (
            <Dropdown.ItemText className="text-center my-5">
              <Spinner variant="primary"></Spinner>
            </Dropdown.ItemText>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default ClientMenu;
