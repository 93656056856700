import React from "react";
import { Modal, Button } from "react-bootstrap";

function ConfirmModal(props) {
  return (
    <Modal show={props.show} onHide={props.handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.question}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          {props.noButtonText}
        </Button>
        <Button variant="primary" onClick={props.handleConfirm}>
          {props.yesButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
