function TextKpi(props) {
  return (
    <div className="mb-1">
      <small className="float-start">{props.text}</small>
      <small className="float-end fw-bold text-muted">{props.percent} %</small>
      <div className="clearfix" />
    </div>
  );
}

export default TextKpi;
