import { TiTick } from "react-icons/ti";
import { MdError } from "react-icons/md";
import { IoIosWarning } from "react-icons/io";
import { FaHourglassStart } from "react-icons/fa";
import { BsPlayFill } from "react-icons/bs";
import { RiTimerLine } from "react-icons/ri";
import Utils from "../../utils";

class StatusHelper {
  static getBadge = (status) => {
    return (
      <div className={`${StatusHelper.getTextClass(status)} text-nowrap`}>
        {status === "NotStarted" && <FaHourglassStart className="me-1 mb-1" />}
        {status === "InProcess" && <BsPlayFill className="me-1 mb-1" />}
        {status === "Completed" && <TiTick className="me-1 mb-1" />}
        {status === "Error" && <MdError className="me-1 mb-1" />}
        {status === "AuthenticationFailed" && <MdError className="me-1 mb-1" />}
        {status === "CompletedWithErrors" && (
          <IoIosWarning className="me-1 mb-1" />
        )}
        {status === "Runbook Timeout" && <RiTimerLine className="me-1 mb-1" />}
        {Utils.fixCamelCase(status)}
      </div>
    );
  };

  static getTextClass = (status) => {
    switch (status) {
      case "Completed":
        return "text-success";
      case "Error":
      case "AuthenticationFailed":
        return "text-danger";
      case "CompletedWithErrors":
      case "Runbook Timeout":
        return "text-warning";
      case "NotStarted":
        return "text-muted";
      case "InProcess":
      default:
        return "text-info";
    }
  };
}

export default StatusHelper;
