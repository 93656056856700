import Utils from "../../utils";

class GridHelper {
  static handlePageClick = (pageNumber, location, navigate) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.current_page = pageNumber;

    GridHelper.refreshParams(queryParams, location, navigate);
  };

  static handleSortClick = (orderBy, orderDirection, location, navigate) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.order_by = orderBy;
    queryParams.order_direction = orderDirection;

    GridHelper.refreshParams(queryParams, location, navigate);
  };

  static handleSearch = (filter, location, navigate) => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams.current_page = 0;

    for (const property in filter) {
      if (filter[property]) queryParams[property] = filter[property];
      else delete queryParams[property];
    }

    GridHelper.refreshParams(queryParams, location, navigate);
  };

  static refreshParams = (queryParamsObj, location, navigate) => {
    const currentPath = location.pathname;
    navigate(`${currentPath}?${Utils.objectToQueryString(queryParamsObj)}`);
  };
}

export default GridHelper;
