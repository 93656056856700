import { runbookStepService } from "../services/runbookStep.service";
import { runbookStepConstants } from "../constants/runbookStep.constants";

export const runbookStepActions = {
  get,
};

function get(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    runbookStepService.get(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: runbookStepConstants.GET_REQUEST,
      payload: requestParameters,
    };
  }
  function success(paginatedResult) {
    return { type: runbookStepConstants.GET_SUCCESS, payload: paginatedResult };
  }
  function failure() {
    return { type: runbookStepConstants.GET_FAILURE };
  }
}
