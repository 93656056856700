import { runbookServerService } from "../services/runbookServer.service";
import { runbookServerConstants } from "../constants/runbookServer.constants";

export const runbookServerActions = {
  getAll,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());

    runbookServerService.getAll().then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: runbookServerConstants.GET_ALL_REQUEST,
    };
  }
  function success(result) {
    return { type: runbookServerConstants.GET_ALL_SUCCESS, payload: result };
  }
  function failure() {
    return { type: runbookServerConstants.GET_ALL_FAILURE };
  }
}
