import { clientConnectorConstants } from "../constants/clientConnector.constants";

export function clientConnector(state = {}, action) {
  switch (action.type) {
    case clientConnectorConstants.GET_REQUEST:
    case clientConnectorConstants.UPDATE_REQUEST:
    case clientConnectorConstants.CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        request: action.payload,
        error: false,
        success: false,
      };
    case clientConnectorConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        paginatedResult: action.payload,
      };
    case clientConnectorConstants.UPDATE_SUCCESS:
    case clientConnectorConstants.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case clientConnectorConstants.GET_FAILURE:
    case clientConnectorConstants.UPDATE_FAILURE:
    case clientConnectorConstants.CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        paginatedResult: {},
      };
    case clientConnectorConstants.CLEAR_SUGGESTIONS_REQUEST:
    case clientConnectorConstants.GET_SUGGESTIONS_REQUEST:
    case clientConnectorConstants.GET_SUGGESTIONS_FAILURE:
      return {
        ...state,
        suggestions: {},
      };
    case clientConnectorConstants.GET_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        suggestions: action.payload,
      };
    default:
      return state;
  }
}
