import { CiClock1 } from "react-icons/ci";
import { TbHandClick } from "react-icons/tb";
import { MdDataObject } from "react-icons/md";
import Utils from "../../utils";

class ExecutionTriggerHelper {
  static getBadge = (executionTrigger) => {
    return (
      <div className="text-nowrap fw-bold me-2">
        {executionTrigger === "Scheduler" && <CiClock1 className="mb-1 me-1" />}
        {executionTrigger === "Manual" && <TbHandClick className="mb-1 me-1" />}
        {executionTrigger === "PushedData" && (
          <MdDataObject className="mb-1 me-1" />
        )}
        {Utils.fixCamelCase(executionTrigger)}
      </div>
    );
  };
}

export default ExecutionTriggerHelper;
