import { runbookExecutionHistoryConstants } from "../constants/runbookExecutionHistory.constants";

export function runbookExecutionHistory(state = {}, action) {
  switch (action.type) {
    case runbookExecutionHistoryConstants.GET_REQUEST:
      return {
        ...state,
        loading: true,
        request: action.payload,
      };
    case runbookExecutionHistoryConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        paginatedResult: action.payload,
      };
    case runbookExecutionHistoryConstants.GET_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
