import React from "react";
import { Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Utils from "../../utils";

const options = [
  "primary",
  "secondary",
  "success",
  "danger",
  "warning",
  "info",
  "light",
  "dark",
];

const VariantDropdown = ({ value, onChange, displayEmpty }) => {
  return (
    <>
      <Form.Select value={value} onChange={onChange}>
        {displayEmpty && <option value="">-</option>}
        {options.map((option) => (
          <option key={option} value={option}>
            {Utils.fixCamelCase(option)}
          </option>
        ))}
      </Form.Select>

      <Alert
        variant={value}
        className="p-2 mt-2"
        style={{ visibility: value ? "visible" : "hidden" }}
      >
        Example broadcast message!
      </Alert>
    </>
  );
};

export default VariantDropdown;
