import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Utils from "../../utils";
import { runbookActions } from "../../actions/runbook.actions";
import Grid from "../common/Grid";
import Title from "../common/Title";
import { CiBoxList, CiClock1 } from "react-icons/ci";
import { FaDatabase, FaPlayCircle } from "react-icons/fa";
import CategoryHelper from "../helpers/categoryHelper";
import StatusHelper from "../helpers/statusHelper";
import ConfirmModal from "../common/ConfirmModal";
import {
  Toast,
  ToastContainer,
  Spinner,
  Modal,
  Button,
  Alert,
} from "react-bootstrap";
import { TiTick } from "react-icons/ti";
import { MdDelete, MdEdit, MdError } from "react-icons/md";
import { IoIosWarning } from "react-icons/io";
import RunbookGridFilter from "./RunbookGridFilter";
import moment from "moment";
import { GiAlarmClock } from "react-icons/gi";
import { CgDetailsMore } from "react-icons/cg";
import { MdGppGood, MdGppBad } from "react-icons/md";
import GridHelper from "../helpers/gridHelper";

function RunbookGrid(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const runbookReducer = useSelector((state) => state.runbook);
  const [confirmRunModalShow, setConfirmRunModalShow] = useState(false);
  const [executeRunbook, setExecuteRunbook] = useState({});
  const [confirmRunToastShow, setConfirmRunToastShow] = useState(false);
  const [confirmValidateModalShow, setConfirmValidateModalShow] =
    useState(false);
  const [validateRunbook, setValidateRunbook] = useState({});
  const [validateResultModalShow, setValidateResultModalShow] = useState(false);
  const [confirmDeleteModalShow, setConfirmDeleteModalShow] = useState(false);
  const [deleteRunbook, setDeleteRunbook] = useState({});

  const filterInitialState = {
    category: "",
    runbookId: "",
    runbookName: "",
    lastExecution: "",
    nextExecution: "",
    status: "",
  };
  const [filter, setFilter] = useState(filterInitialState);

  let actions = [
    {
      text: "Details",
      url: "details?runbookId={id}",
      icon: <CgDetailsMore className="mb-1 me-2" />,
    },
    {
      text: "Latest executions",
      url: "executions?runbookId={id}", // For URL redirect
      icon: <CiClock1 className="mb-1 me-2" />,
    },
    {
      text: "Add Scheduler",
      url: "/scheduler/add?runbookId={id}",
      icon: <GiAlarmClock className="mb-1 me-2" />,
    },
    {
      text: "Manage Connectors",
      url: "connector?runbookId={id}",
      icon: <FaDatabase className="mb-1 me-2" />,
    },
    {
      text: "Run",
      event: (id) => {
        const runbookToExecute = runbookReducer.paginatedResult.data.find(
          (r) => r.runbookId === id
        );

        setExecuteRunbook(runbookToExecute);
        setConfirmRunModalShow(true);
      },
      icon: <FaPlayCircle className="mb-1 me-2" />,
    },
    {
      text: "Validate",
      event: (id) => {
        const runbookToValidate = runbookReducer.paginatedResult.data.find(
          (r) => r.runbookId === id
        );

        setValidateRunbook(runbookToValidate);
        setConfirmValidateModalShow(true);
      }, // For event dispach
      icon: <MdGppGood className="mb-1 me-2" />,
    },
    {
      text: "Edit",
      url: "edit?runbookId={id}",
      icon: <MdEdit className="mb-1 me-2" />,
    },
    {
      text: "Delete",
      event: (id) => {
        const runbookToDelete = runbookReducer.paginatedResult.data.find(
          (r) => r.runbookId === id
        );
        setDeleteRunbook(runbookToDelete);
        setConfirmDeleteModalShow(true);
      },
      icon: <MdDelete className="mb-1 me-2" />, // Change the icon as needed
    },
  ];

  const refreshGrid = () => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams["clientId"] = props.user.clientId;
    queryParams["clientGuid"] = props.user.clientGuid;

    dispatch(runbookActions.get(queryParams));
  };

  useEffect(() => {
    refreshGrid();

    // Initialize filter from URL
    let queryParams = Utils.getQueryStringParams(location);
    setFilter({
      category: queryParams["category"],
      runbookId: queryParams["runbookId"],
      runbookName: queryParams["runbookName"],
      lastExecution: queryParams["lastExecution"],
      nextExecution: queryParams["nextExecution"],
      status: queryParams["status"],
    });

    // eslint-disable-next-line
  }, [location]);

  const handleClearFilters = () => {
    GridHelper.handleSearch(filterInitialState, location, navigate);
  };

  const handleExecuteRunbook = () => {
    setConfirmRunModalShow(false);
    setConfirmRunToastShow(true);

    // Dispatch action for execute runbook
    let requestBody = {
      runbookId: executeRunbook.runbookId,
      clientId: executeRunbook.clientId,
      runbookGuid: executeRunbook.runbookGuid,
      clientGuid: executeRunbook.clientGuid,
    };

    dispatch(runbookActions.execute(requestBody));
  };

  const handleValidateRunbook = () => {
    setConfirmValidateModalShow(false);
    setValidateResultModalShow(true);

    // Dispatch action for validate runbook
    let requestBody = {
      runbookId: validateRunbook.runbookId,
      clientId: validateRunbook.clientId,
      runbookGuid: validateRunbook.runbookGuid,
      clientGuid: validateRunbook.clientGuid,
    };

    dispatch(runbookActions.validate(requestBody));
  };

  const handleDeleteRunbook = () => {
    setConfirmDeleteModalShow(false);
    let requestParameters = {
      runbookId: deleteRunbook.runbookId,
      clientid: props.user.clientId,
      clientGuid: props.user.clientGuid,
    };

    dispatch(runbookActions.remove(requestParameters));
  };

  useEffect(() => {
    if (!validateResultModalShow) {
      const wasValid = validateRunbook.isValid;
      const isValid = !runbookReducer?.validateResponse?.hasErrors;
      if (wasValid !== isValid) refreshGrid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateResultModalShow]);

  const runbookFormattedName = (runbook) => {
    return `${runbook.runbookId} - ${runbook.runbookName} (${runbook.description})`;
  };

  let displayColumns = [
    "category",
    "runbookName",
    "description",
    "lastExecution",
    "nextExecution",
    "status",
    "isValid",
  ];
  if (props.user.isSuperUser) displayColumns.push("clientName");

  const data = runbookReducer.paginatedResult?.data.map((r) => {
    return {
      ...r,
      lastExecution: r.lastExecution
        ? moment(Utils.convertToDateTime(r.lastExecution)).fromNow()
        : "-",
      nextExecution: r.nextExecution
        ? moment(Utils.convertToDateTime(r.nextExecution)).fromNow()
        : "-",
      category: CategoryHelper.getBadge(r.category, r.runbookId),
      status: StatusHelper.getBadge(r.status),
      isValid: r.isValid ? (
        <div className="text-success">
          <MdGppGood /> Valid
        </div>
      ) : (
        <div className="text-danger">
          <MdGppBad /> Invalid
        </div>
      ),
    };
  });

  return (
    <>
      <Title text="My Runbooks" icon={<CiBoxList className="mb-1" />} />

      <RunbookGridFilter filter={filter} onClearFilters={handleClearFilters} />

      {props.user.clientId ? (
        <Link to={"add"} className="btn btn-primary float-end mb-2">
          Add Runbook
        </Link>
      ) : (
        <Alert variant="info">
          In order to see the Add Runbook button, please select a Client from
          the top menu's Clients dropdown.
        </Alert>
      )}

      <Grid
        data={data}
        displayColumns={displayColumns}
        idColumn={"runbookId"}
        idColumn2={"runbookGuid"}
        actions={actions}
        pageNumber={runbookReducer.paginatedResult?.pageNumber ?? 0}
        totalPages={runbookReducer.paginatedResult?.totalPages ?? 0}
        loading={runbookReducer.loading}
        onPageChange={GridHelper.handlePageClick}
        onSort={GridHelper.handleSortClick}
      ></Grid>

      <ConfirmModal
        show={confirmDeleteModalShow}
        title={`Delete Runbook - ${deleteRunbook.runbookId}`}
        question={`Are you sure you want to delete ${runbookFormattedName(
          deleteRunbook
        )}?`}
        noButtonText="No"
        yesButtonText="Yes, please"
        handleClose={() => setConfirmDeleteModalShow(false)}
        handleConfirm={handleDeleteRunbook}
      />

      <ConfirmModal
        show={confirmRunModalShow || confirmValidateModalShow}
        title={`${
          confirmRunModalShow
            ? `Execute Runbook - ${executeRunbook.runbookId}`
            : `Validate Runbook - ${validateRunbook.runbookId}`
        }`}
        question={`Are you sure you want to ${
          confirmRunModalShow ? "execute" : "validate"
        } ${runbookFormattedName(
          confirmRunModalShow ? executeRunbook : validateRunbook
        )}?`}
        noButtonText="No"
        yesButtonText="Yes, please"
        handleClose={() => {
          setConfirmRunModalShow(false);
          setConfirmValidateModalShow(false);
        }}
        handleConfirm={() => {
          confirmRunModalShow
            ? handleExecuteRunbook()
            : handleValidateRunbook();
        }}
      />

      <ToastContainer
        className="p-3"
        position={"bottom-end"}
        style={{ zIndex: 1 }}
      >
        <Toast
          onClose={() => setConfirmRunToastShow(false)}
          show={confirmRunToastShow}
        >
          <Toast.Header>
            <strong className="me-auto">
              Execute Runbook {executeRunbook.runbookId}
            </strong>
            <small>Now</small>
          </Toast.Header>
          <Toast.Body>
            {runbookReducer.loadingExecute && (
              <>
                Sending {runbookFormattedName(executeRunbook)} to the queue...
                <Spinner
                  className="float-end"
                  size="sm"
                  variant="primary"
                ></Spinner>
                <div className="clearfix"></div>
              </>
            )}
            {!runbookReducer.loadingExecute && (
              <>
                {runbookReducer.executeResponse && (
                  <>
                    {runbookReducer.executeResponse.isSuccessful ? (
                      <TiTick className="text-success me-2 mb-1" />
                    ) : (
                      <IoIosWarning className="text-warning me-2 mb-1" />
                    )}
                    {runbookReducer.executeResponse.message}
                  </>
                )}
                {runbookReducer.executeError && (
                  <>
                    <MdError className="text-danger me-2 mb-1" />
                    {runbookReducer.executeError}
                  </>
                )}
              </>
            )}
          </Toast.Body>
        </Toast>
      </ToastContainer>

      {validateRunbook && (
        <Modal
          show={validateResultModalShow}
          onHide={() => setValidateResultModalShow(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Validate Runbook - {validateRunbook.runbookId}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {runbookReducer.loadingValidate && (
              <>
                Validating {runbookFormattedName(validateRunbook)}...
                <Spinner
                  className="float-end"
                  size="sm"
                  variant="primary"
                ></Spinner>
                <div className="clearfix"></div>
              </>
            )}

            {!runbookReducer.loadingValidate && (
              <>
                {runbookReducer.validateResponse && (
                  <>
                    {!runbookReducer.validateResponse.hasErrors ? (
                      <div className="text-success">
                        <MdGppGood className="me-2 mb-1" />
                        Validation successful!
                      </div>
                    ) : (
                      <div className="text-danger">
                        <MdGppBad className="me-2 mb-1" />
                        {runbookReducer.validateResponse.message ||
                          "Validation failed. Please check the details."}
                      </div>
                    )}
                  </>
                )}

                {runbookReducer.validateError && (
                  <div className="text-danger">
                    <MdGppBad className="me-2 mb-1" />
                    {runbookReducer.validateError}
                  </div>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setValidateResultModalShow(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default RunbookGrid;
