import React from "react";
import Title from "../common/Title";
import { RiDashboard3Line } from "react-icons/ri";
import KPIs from "./KPIs";
import LatestExecutions from "./LatestExecutions";
import NextExecutions from "./NextExecutions";
import { Row, Col } from "react-bootstrap";

function Dashboard(props) {
  return (
    <>
      <Title text="Dashboard" icon={<RiDashboard3Line className="mb-1" />} />
      <div className="mb-3">
        Note: Statistics shown are based on the latest 30 executions per
        Runbook. Only the most recent 30 executions are saved.
      </div>
      <KPIs user={props.user} />
      <Row>
        <Col>
          <LatestExecutions user={props.user} />
        </Col>
        <Col>
          <NextExecutions user={props.user} />
        </Col>
      </Row>
    </>
  );
}

export default Dashboard;
