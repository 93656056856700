import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Utils from "../../utils";
import { runbookExecutionHistoryActions } from "../../actions/runbookExecutionHistory.actions";
import Grid from "../common/Grid";
import Title from "../common/Title";
import { CiBoxList, CiClock1 } from "react-icons/ci";
import { MdError } from "react-icons/md";
import Subtitle from "../common/Subtitle";
import StatusHelper from "../helpers/statusHelper";
import ExecutionTriggerHelper from "../helpers/executionTriggerHelper";
import useRunbookTitle from "../../hooks/runbookTitle";
import GridHelper from "../helpers/gridHelper";

function RunbookExecutionHistoryGrid(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const runbookExecutionHistoryReducer = useSelector(
    (state) => state.runbookExecutionHistory
  );
  const { runbookTitle, runbookTitleLoading } = useRunbookTitle();

  const actions = [
    {
      text: "History Lines",
      url: "lines?executionId={id}",
      icon: <MdError className="mb-1 me-2" />,
    },
  ];

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);
    queryParams["clientId"] = props.user.clientId;
    queryParams["clientGuid"] = props.user.clientGuid;

    dispatch(runbookExecutionHistoryActions.get(queryParams));
    // eslint-disable-next-line
  }, [location]);

  let displayColumns = [
    "executionTrigger",
    "executionId",
    "startTime",
    "endTime",
    "schedulerId",
    "ranByUser",
    "status",
  ];

  const data = runbookExecutionHistoryReducer.paginatedResult?.data.map(
    (eh) => {
      return {
        ...eh,
        startTime: Utils.convertToDateTime(eh.startTime),
        endTime: Utils.convertToDateTime(eh.endTime),
        status: StatusHelper.getBadge(eh.status),
        executionTrigger: ExecutionTriggerHelper.getBadge(eh.executionTrigger),
      };
    }
  );

  const loading = runbookTitleLoading || runbookExecutionHistoryReducer.loading;

  return (
    <>
      <Title text="Execution History" icon={<CiClock1 className="mb-1" />} />
      {!loading && (
        <Subtitle text={runbookTitle} icon={<CiBoxList className="mb-1" />} />
      )}

      <Grid
        data={data}
        displayColumns={displayColumns}
        idColumn={"executionId"}
        actions={actions}
        pageNumber={
          runbookExecutionHistoryReducer.paginatedResult?.pageNumber ?? 0
        }
        totalPages={
          runbookExecutionHistoryReducer.paginatedResult?.totalPages ?? 0
        }
        loading={loading}
        onPageChange={GridHelper.handlePageClick}
        onSort={GridHelper.handleSortClick}
      ></Grid>
    </>
  );
}

export default RunbookExecutionHistoryGrid;
