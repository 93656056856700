import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { invitationService } from "../../services/invitation.service";
import Utils from "../../utils";
import { Link, useLocation } from "react-router-dom";
import { useJwt } from "react-jwt";
import { Alert, Form, Button, Container, Spinner } from "react-bootstrap";
import PasswordStrengthBar from "react-password-strength-bar";
import { userActions } from "../../actions/user.actions";
import PasswordInput from "../common/PasswordInput";

function Register() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [invitationError, setInvitationError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmPass, setPasswordConfirmPass] = useState(true);
  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
  const [passwordStrenghtPass, setPasswordStrenghtPass] = useState(true);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const { decodedToken, isExpired } = useJwt(token);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    let queryParams = Utils.getQueryStringParams(location);
    setLoading(true);
    invitationService.get(queryParams["t"]).then(
      (response) => {
        setToken(response.data.token);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        setInvitationError(true);
      }
    );

    // eslint-disable-next-line
  }, [location]);

  const handleCreateAccount = async () => {
    setSubmitted(true);
    setPasswordConfirmPass(true);
    setPasswordStrenghtPass(true);

    if (password !== passwordConfirm) setPasswordConfirmPass(false);
    else if (password.length > 0 && passwordStrengthScore < 2)
      setPasswordStrenghtPass(false);
    else {
      setPasswordConfirmPass(true);

      if (token && firstName && lastName && password)
        dispatch(
          userActions.create(
            token,
            firstName,
            lastName,
            decodedToken.Email,
            decodedToken.ClientId,
            decodedToken.ClientGuid,
            password
          )
        );
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") handleCreateAccount();
  };

  return (
    <div className="centered-div">
      <Container
        className="login-box bg-white p-5 rounded border border-secondary mt-5"
        onKeyDown={onKeyDown}
      >
        <div className="text-center mb-5">
          <img
            src={require("../../assets/armanino-llp.png")}
            alt="Armanino"
            width={250}
          />
        </div>

        {user.createSuccess && (
          <Alert variant={"success"}>
            Your user account was created succesfully. Please click to redirect
            to{" "}
            <Link to={"/login"} className="text-decoration-none">
              login
            </Link>
            .
          </Alert>
        )}

        {!user.createSuccess && (
          <>
            {loading && (
              <center>
                <Spinner className="text-primary mt-3" />
                <br />
                <div className="mt-3">Loading your invitation</div>
              </center>
            )}

            {!loading && (
              <>
                {invitationError && (
                  <Alert variant={"danger"}>
                    Your invitation was not found. Please request the inviter to
                    send a new one.
                  </Alert>
                )}

                {isExpired && !invitationError && (
                  <Alert variant={"danger"}>
                    Your invitation link has expired. Please request the inviter
                    to send a new one.
                  </Alert>
                )}

                {user.createFailed && (
                  <Alert variant={"danger"}>
                    An error has occurred. Please contact Armanino in order to
                    configure your user.
                  </Alert>
                )}

                {!isExpired && decodedToken && (
                  <>
                    <Alert variant={"info"}>
                      You've been invited by{" "}
                      <strong>{decodedToken.InvitedBy}</strong> to register and
                      access the Armanino Integration Portal. Please fill in
                      your details to create your user.
                    </Alert>

                    {!passwordConfirmPass && (
                      <Alert variant={"danger"}>
                        Passwords do not match. Please enter them again below
                        and make sure they match.
                      </Alert>
                    )}

                    {!passwordStrenghtPass && (
                      <Alert variant={"danger"}>
                        Please choose a stronger password. Try mix of letters,
                        numbers, and symbols.
                      </Alert>
                    )}

                    <Form.Group controlId="formBasicEmail" className="mb-3">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        value={decodedToken.Email}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicFirstName" className="mb-3">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className={submitted && !firstName ? "is-invalid" : ""}
                        autoComplete="new-password"
                      />
                      <Form.Control.Feedback type="invalid">
                        <small>Please provide your first name.</small>
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formBasicLastName" className="mb-3">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className={submitted && !lastName ? "is-invalid" : ""}
                        autoComplete="new-password"
                      />
                      <Form.Control.Feedback type="invalid">
                        <small>Please provide your last name.</small>
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <PasswordInput
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        additionalCss={submitted && !password ? "is-invalid" : ""}
                      />
                      <Form.Control.Feedback type="invalid">
                        <small>Please provide your password.</small>
                      </Form.Control.Feedback>

                      <PasswordStrengthBar
                        password={password}
                        onChangeScore={(score) => {
                          setPasswordStrengthScore(score);
                        }}
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicPasswordConfirm">
                      <Form.Label>Confirm your password</Form.Label>
                      <PasswordInput
                        placeholder="Confirm your Password"
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        additionalCss={submitted && !passwordConfirm ? "is-invalid" : ""}
                      />
                      <Form.Control.Feedback type="invalid">
                        <small>
                          Please provide your password confirmation.
                        </small>
                      </Form.Control.Feedback>
                    </Form.Group>

                    <div className="d-grid gap-2 my-3">
                      <Button
                        variant="primary"
                        type="submit"
                        className="mt-3"
                        size="lg"
                        onClick={handleCreateAccount}
                        disabled={user.loading}
                      >
                        {!user.loading ? (
                          <>Create account</>
                        ) : (
                          <Spinner size="sm" />
                        )}
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Container>
    </div>
  );
}

export default Register;
