import { clientVariableConstants } from "../constants/clientVariable.constants";

export function clientVariable(state = {}, action) {
  switch (action.type) {
    case clientVariableConstants.GET_REQUEST:
    case clientVariableConstants.UPDATE_REQUEST:
    case clientVariableConstants.CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        request: action.payload,
        error: false,
        success: false,
      };
    case clientVariableConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        paginatedResult: action.payload,
      };
    case clientVariableConstants.UPDATE_SUCCESS:
    case clientVariableConstants.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case clientVariableConstants.GET_FAILURE:
    case clientVariableConstants.UPDATE_FAILURE:
    case clientVariableConstants.CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
