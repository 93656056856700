import { http } from "../api/http";
import Utils from "../utils";

export const clientService = {
  get,
  create,
  renew,
};

function get(requestParameters) {
  let url = `/client/get?${Utils.objectToQueryString(requestParameters)}`;

  return http.get(url).then((response) => {
    return response;
  });
}

function create(requestBody) {
  let url = `/client/create`;

  return http.post(url, JSON.stringify(requestBody)).then((response) => {
    return response;
  });
}

function renew(requestBody) {
  let url = `/client/renew`;

  return http.patch(url, JSON.stringify(requestBody)).then((response) => {
    return response;
  });
}
