import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { runbookActions } from "../actions/runbook.actions";
import Utils from "../utils";

const useRunbookTitle = (runbookId) => {
  const [runbookTitle, setRunbookTitle] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientGuid, setClientGuid] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const runbookStore = useSelector((state) => state.runbook);

  useEffect(() => {
    const id = runbookId ?? Utils.getQueryStringParams(location)["runbookId"];

    if (id) {
      const runbookInStore = runbookStore.paginatedResult?.data?.find(
        (r) => r.runbookId === id
      );

      if (runbookInStore) {
        setRunbookTitle(
          `${runbookInStore.runbookName ?? ""} - ${
            runbookInStore.description ?? ""
          }`
        );
        setClientId(runbookInStore.clientId);
        setClientGuid(runbookInStore.clientGuid);
      } else {
        dispatch(runbookActions.get({ runbookId: id }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, dispatch, runbookStore.paginatedResult?.data, runbookId]);

  useEffect(() => {
    const id = runbookId ?? Utils.getQueryStringParams(location)["runbookId"];
    const runbookInStore = runbookStore.paginatedResult?.data?.find(
      (r) => r.runbookId === id
    );

    if (runbookInStore) {
      setRunbookTitle(
        `${runbookInStore.runbookName ?? ""} - ${
          runbookInStore.description ?? ""
        }`
      );
      setClientId(runbookInStore.clientId);
      setClientGuid(runbookInStore.clientGuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runbookStore, location, runbookId]);

  return {
    runbookTitle: `Runbook ${runbookTitle}`,
    runbookTitleLoading: runbookStore.loading,
    clientId,
    clientGuid,
  };
};

export default useRunbookTitle;
