import { runbookConnectorService } from "../services/runbookConnector.service";
import { runbookConnectorConstants } from "../constants/runbookConnector.constants";

export const runbookConnectorActions = {
  get,
  create,
  remove,
};

function get(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    runbookConnectorService.get(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: runbookConnectorConstants.GET_REQUEST,
      payload: requestParameters,
    };
  }
  function success(paginatedResult) {
    return {
      type: runbookConnectorConstants.GET_SUCCESS,
      payload: paginatedResult,
    };
  }
  function failure() {
    return { type: runbookConnectorConstants.GET_FAILED };
  }
}

function create(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    runbookConnectorService
      .create(requestParameters)
      .then(
        () => {
          dispatch(success());
        },
        (error) => {
          dispatch(failure());
        }
      )
      .then(() => {
        // Refresh the connectors for this runbook
        dispatch(get(requestParameters));
      });
  };

  function request(requestParameters) {
    return {
      type: runbookConnectorConstants.CREATE_REQUEST,
      payload: requestParameters,
    };
  }
  function success() {
    return { type: runbookConnectorConstants.CREATE_SUCCESS };
  }
  function failure() {
    return { type: runbookConnectorConstants.CREATE_FAILED };
  }
}

function remove(requestParameters) {
  return (dispatch) => {
    dispatch(request());

    runbookConnectorService.remove(requestParameters).then(
      () => {
        window.location.reload();
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request() {
    return {
      type: runbookConnectorConstants.DELETE_REQUEST,
    };
  }
  function failure() {
    return { type: runbookConnectorConstants.DELETE_FAILED };
  }
}
