export const clientConnectorConstants = {
  GET_REQUEST: "GET_CLIENT_CONNECTOR_REQUEST",
  GET_SUCCESS: "GET_CLIENT_CONNECTOR_SUCCESS",
  GET_FAILURE: "GET_CLIENT_CONNECTOR_FAILURE",
  CLEAR_SUGGESTIONS_REQUEST: "GET_SUGGESTIONS_CLIENT_CONNECTOR_REQUEST",
  GET_SUGGESTIONS_REQUEST: "GET_SUGGESTIONS_CLIENT_CONNECTOR_REQUEST",
  GET_SUGGESTIONS_SUCCESS: "GET_SUGGESTIONS_CLIENT_CONNECTOR_SUCCESS",
  GET_SUGGESTIONS_FAILURE: "GET_SUGGESTIONS_CLIENT_CONNECTOR_FAILURE",
  CREATE_REQUEST: "CREATE_CLIENT_CONNECTOR_REQUEST",
  CREATE_SUCCESS: "CREATE_CLIENT_CONNECTOR_SUCCESS",
  CREATE_FAILURE: "CREATE_CLIENT_CONNECTOR_FAILURE",
  UPDATE_REQUEST: "UPDATE_CLIENT_CONNECTOR_REQUEST",
  UPDATE_SUCCESS: "UPDATE_CLIENT_CONNECTOR_SUCCESS",
  UPDATE_FAILURE: "UPDATE_CLIENT_CONNECTOR_FAILURE",
};
