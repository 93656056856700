import { dashboardService } from "../services/dashboard.service";
import { dashboardConstants } from "../constants/dashboard.constants";

export const dashboardActions = {
  get,
};

function get(requestParameters) {
  return (dispatch) => {
    dispatch(request(requestParameters));

    dashboardService.get(requestParameters).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };

  function request(requestParameters) {
    return {
      type: dashboardConstants.GET_KPIS_REQUEST,
      payload: requestParameters,
    };
  }
  function success(response) {
    return {
      type: dashboardConstants.GET_KPIS_SUCCESS,
      payload: response,
    };
  }
  function failure() {
    return { type: dashboardConstants.GET_KPIS_FAILURE };
  }
}
