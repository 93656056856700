import React from "react";

function Title({ text, icon }) {
  return (
    <>
      <h4 className="mb-2">
        {icon} {text}
      </h4>
      <hr className="mt-2" />
    </>
  );
}
export default Title;
