import { CiClock1 } from "react-icons/ci";
import { TbHandClick } from "react-icons/tb";
import Utils from "../../utils";

class CategoryHelper {
  static getBadge = (category, id) => {
    return (
      <div className="text-nowrap me-2">
        {category === "OnScheduled" ? (
          <CiClock1 className="mb-1 me-1" />
        ) : (
          <TbHandClick className="mb-1 me-1" />
        )}{" "}
        <b>{id}</b> - {Utils.fixCamelCase(category)}
      </div>
    );
  };
}

export default CategoryHelper;
