import { http } from "../api/http";
import Utils from "../utils";

export const runbookExecutionHistoryService = {
  get,
  getHistoryLine,
  getLatestExecutions,
};

function get(requestParameters) {
  let url = `/runbook/executionhistory?${Utils.objectToQueryString(
    requestParameters
  )}`;

  return http.get(url).then((response) => {
    return response;
  });
}

function getHistoryLine(requestParameters) {
  let url = `/runbook/executionhistory/line?${Utils.objectToQueryString(
    requestParameters
  )}`;

  return http.get(url).then((response) => {
    return response;
  });
}

function getLatestExecutions(requestParameters) {
  let url = `/runbook/executionhistory/getlatestexecutions?${Utils.objectToQueryString(
    requestParameters
  )}`;

  return http.get(url).then((response) => {
    return response;
  });
}
