import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Collapse } from "react-bootstrap";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import GridHelper from "../helpers/gridHelper";

function VariableLineGridFilter(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    if (props.filter) setFilter(props.filter);
    const filterIsEmpty = Object.values(props.filter).every(
      (x) => x === null || x === "" || x === undefined
    );
    setExpanded(!filterIsEmpty);

    // eslint-disable-next-line
  }, [props]);

  const onKeyDown = (e) => {
    if (e.key === "Enter") GridHelper.handleSearch(filter, location, navigate);
  };

  return (
    <div className="bg-light border p-3 mb-3 rounded" onKeyDown={onKeyDown}>
      <Collapse in={expanded}>
        <div>
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Variable Line Id</Form.Label>
                <Form.Control
                  value={filter.variableLineId ?? ""}
                  onChange={(e) =>
                    setFilter({ ...filter, variableLineId: e.target.value })
                  }
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Variable Key</Form.Label>
                <Form.Control
                  value={filter.variableKey ?? ""}
                  onChange={(e) =>
                    setFilter({ ...filter, variableKey: e.target.value })
                  }
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Variable Value</Form.Label>
                <Form.Control
                  value={filter.variableValue ?? ""}
                  onChange={(e) =>
                    setFilter({ ...filter, variableValue: e.target.value })
                  }
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="link"
            size="sm"
            className="text-decoration-none p-0 m-0 float-start mt-4 fw-bold"
            onClick={() => setExpanded(!expanded)}
          >
            Click here to collapse filters <FaAngleUp size={13} />
          </Button>
          <Button
            variant="primary"
            className="float-end mt-3"
            onClick={() => GridHelper.handleSearch(filter, location, navigate)}
          >
            Search
          </Button>
          <Button
            variant="secondary"
            className="float-end mt-3 me-2"
            onClick={() => props.onClearFilters()}
          >
            Clear filters
          </Button>
          <div className="clearfix" />
        </div>
      </Collapse>
      {!expanded && (
        <Button
          variant="link"
          size="sm"
          className="text-decoration-none p-0 m-0 fw-bold"
          onClick={() => setExpanded(!expanded)}
        >
          Click here to expand filters <FaAngleDown size={13} />
        </Button>
      )}
    </div>
  );
}

export default VariableLineGridFilter;
