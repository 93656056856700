import { clientVariableLineConstants } from "../constants/clientVariableLine.constants";

export function clientVariableLine(state = {}, action) {
  switch (action.type) {
    case clientVariableLineConstants.GET_REQUEST:
    case clientVariableLineConstants.CREATE_REQUEST:
    case clientVariableLineConstants.UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        request: action.payload,
        error: false,
        success: false,
      };
    case clientVariableLineConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        paginatedResult: action.payload,
      };
    case clientVariableLineConstants.CREATE_SUCCESS:
    case clientVariableLineConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case clientVariableLineConstants.DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case clientVariableLineConstants.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case clientVariableLineConstants.CREATE_FAILURE:
    case clientVariableLineConstants.GET_FAILURE:
    case clientVariableLineConstants.DELETE_FAILURE:
    case clientVariableLineConstants.UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
