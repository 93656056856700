import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import Login from "../Login";

function LogInRoute() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get("returnUrl") ?? "/dashboard";

  return !Cookies.get("user") ? <Login /> : <Navigate to={redirectUrl} />;
}

export default LogInRoute;
